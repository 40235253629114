.explanation{
  &__header{
    padding-top: 0 !important;
  }

  &__content{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 3;
  }

  &__img{
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    justify-content: center;
    position: relative;

    @media only screen and (max-width: 967px){
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center;
      margin-bottom: 24px;
    }

    img{
      @media only screen and (max-width: 767px){ 
        width: 100%;
      }
    }
  }

  &__description{
    // padding-top: 40px;
    padding-left: 40px;
    flex: 1;

    @media only screen and (max-width: 967px){
      padding-left: 0;
      text-align: center;
      // padding-top: 0;
    }
  }

  &__title{
    color: var(--color-gold);
    margin-bottom: 32px;

    @media only screen and (max-width: 1023px){
      font-size: 42px !important;
    }

    @media only screen and (max-width: 967px){
      font-size: 36px !important;
      margin-bottom: 8px;
    }
  }
  &__text{
    color: white;
    font-family: var(--font-2);
    font-size: 16px;
  }

  &__title,
  &__text{
    max-width: 478px;

    @media only screen and (max-width: 967px){
      max-width: 448px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__left{
    .explanation{
      &__image{
        order: 3;

        @media only screen and (max-width: 967px){
          order: -1;
        } 
      }
      &__description{}
    }
  }

  &__right{
    .explanation{
      &__title,
      &__text{
        margin-left: auto;
      }
    }
  }
}



.content{
  &__space{
    padding-bottom: 0px !important;
  }

  &__nospacetop{
    padding-top: 0 !important;
  }
  
  &__center{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;

    position: relative;
    z-index: 3;
  }

  &__image{
    flex: 0 0 432px;
    max-width: 432px;

    @media only screen and (max-width: 967px){
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center;
      margin-bottom: 24px;
    }
  }

  &__description{
    padding-left: 40px;
    flex: 1;

    @media only screen and (max-width: 967px){
      padding-left: 0;
      text-align: center;
    }
  }

  &__title{
    color: var(--color-gold);
    margin-bottom: 32px;

    @media only screen and (max-width: 1023px){
      font-size: 42px !important;
    }

    @media only screen and (max-width: 967px){
      font-size: 36px !important;
      margin-bottom: 8px;
    }
  }
  &__text{
    color: white;
    font-family: var(--font-2);
    font-size: 16px;
  }

  &__title,
  &__text{
    max-width: 478px;

    @media only screen and (max-width: 967px){
      max-width: 448px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__image{
    position: relative;
    // transform-style: preserve-3d;
    // will-change: transform;
    // transition: transform .5s;
    // &:hover{
    //   transform: translateZ(10px) rotateX(20deg) rotateY(20deg);
    // }
    > div{
      @media only screen and (max-width: 767px){
        transform: none !important;
      } 
    }

    img{
      @media only screen and (max-width: 767px){ 
        width: 100%;
      }
    }
  }

  &__left{

    .content__image{
      order: 3;

      @media only screen and (max-width: 967px){
        order: -1;
      }
    }

    .content__description{
      padding-left: 0;
      padding-right: 40px;
      @media only screen and (max-width: 967px){
        padding: 0;
      }
    }
  }

  &__right{
    .content__title,
    .content__text{
      margin-left: auto;
    }
  }
}
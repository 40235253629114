.MuiChip-root:not(:last-child){
  margin-right: 0.5em;
}

.MuiChip-root.MuiChip-color {

  &Primary {
    background-color: $primary-color;

    &:hover {
      background-color: $primary-color-dark;
    }
  }

  &Secondary {
    background-color: $secondary-color;

    &:hover {
      background-color: $secondary-color-dark;
    }
  }

  &Warning {
    background-color: $warning-color;
    color: white;

    &:hover {
      background-color: $warning-color-dark;
    }
  }

  &Error {
    background-color: $error-color;
    color: white;

    &:hover {
      background-color: $error-color-dark;
    }
  }

  &Info {
    background-color: $info-color;
    color: white;

    &:hover {
      background-color: $info-color-dark;
    }
  }

  &Success {
    background-color: $success-color;
    color: white;

    &:hover {
      background-color: $success-color-dark;
    }
  }
}

.form-field__note {
  // Used as information
  padding: 16px !important;
  margin-bottom: 16px;
  background: var(--color-yellow-light);
  font-size: 13px;
  color: var(--color-grey-secondary);
}

.MuiFormControl-root.form-field__cname {
  // Only for cname at tenant
  min-width: 400px;
  margin-top: 12px;

  @media screen and (max-width: 678px) {
    min-width: initial;
    width: 100%;
  }

  .MuiInputBase-root.MuiInput-formControl {
    padding: 16px 22px;
    border: 1px solid var(--color-border);
    border-radius: 4px;

    @media screen and (max-width: 1024px) {
      padding: 12px 14px;
    }
  }
}


.form-field__financial-instrument{
  margin-top: 16px;

  > div{
    border: 1px solid var(--color-border);

    &:first-child{
      border-top-left-radius: var(--radius-small);
      border-top-right-radius: var(--radius-small);
    }

    &:last-child{
      border-bottom-left-radius: var(--radius-small);
      border-bottom-right-radius: var(--radius-small);
    }
  }

  &-header{
    &.MuiBox-root{
      padding: 16px 22px;
    }
  }

  &-option{
    &.MuiBox-root{
      padding: 16px 22px;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
    }

    .option{
      &-icon{
        align-self: end;
        width: 40px;
        padding-top: 6px;
        .MuiSvgIcon-root{
          color: var(--color-blue);
        }
      }
      &-desc{
        flex: 1;
        display: flex;
        flex-flow: column nowrap;
        > span{
          display: block;
        }
        p{
          margin-top: 0px;
          color: var(--color-grey) !important;
          font-size: var(--paragraph-max-font-size);
          display: block;
        }
      }
      &-select{
        @media screen and (max-width: 520px) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-top: 16px;
        }
        .btn{
          box-shadow: none;
          padding-top: 4px;
          padding-bottom: 4px;

          @media screen and (max-width: 520px){
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }

  &-detail{
    border-top: none !important;
    // border-bottom: none !important;
    padding: 16px 22px;

    &-notebook{
      margin: 0 !important;
    }
  }
}

.form-field__financial-currency{
  &-checkbox,
  &-select,
  &-number{
    .form-field{
      &__label{
        width: 100%;
        margin-bottom: 12px;
      }
    }
  }
  &-checkbox{
    .form-field{
      &__checkbox{
        margin-right: 18px;

        .MuiFormControlLabel-label{
          color: var(--color-black-secondary);
          padding-left: 4px;
        }
      }
    }
  }
}

.form-field__brands{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;

  .card-brand{
    &__logo{
      width: 157px;
    }
    &__dataroom{
      width: 230px;
    }
    &__refund{
      width: 360px;
    }
    &__withdrawal{
      flex: 1;
    }

    @include max768{
      width: 50%;
      max-width: 50%;
      min-height: 180px;
    }

    @include max578{
      width: 100%;
      max-width: 100%;
      min-height: 100px;
    }
  }
}
.card-brand{
  .component-upload{
    p{
      strong{
        font-weight: 400;
        color: var(--color-grey) !important;
        font-size: var(--paragraph-max-font-size);
        display: block;
        margin-bottom: 4px;
      }
    }
    button{
      border-radius: .4em;
      padding: .2em .4em;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      background-color: #F2F4F6;
      color: rgba(119, 140, 162, 1);
      box-shadow: none;
  
      &:hover,
      &:focus{
        background-color: var(--color-blue);
        color: var(--color-white);
      }
    }

    + p{
      color: var(--color-grey);
      font-size: var(--paragraph-min-font-size);
    }
  }
  .MuiLink-root{
    font-size: var(--paragraph-min-font-size);
    font-weight: 600;
    color: var(--color-blue);
    text-transform: uppercase;

    &:hover,
    &:focus{
      color: var(--color-blue-hover);
    }
  }
}

// .video-tenant{
//   width: 100%;
//   min-height: 350px;
// }
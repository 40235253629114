.explore{
  &__top{
    .tablet-show{
      margin-right: 16px;
      flex: 1;

      @include mq_767{
        margin-right: 0;
      }
    }
  }
  &__nav{
    position: relative;
    left: 0;
    top: 0;
    transform: translateX(0);
    margin-left: -12px;
    margin-right: -12px;
    align-items: center;
  }

  &__list{
    @include mq_767{
      margin: 0;
    }
  }

  &__filter{
    padding: 0px 24px;
  }

  &__pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    width: 100%;

    &_button{
      position: relative;
      margin: 0 6px;
      font-family: $font_dmsans;
      font-size: 14px;
      color: $color-accent-white;

      &:disabled{
        cursor: not-allowed;
        background-color: $color-accent-primary !important;
      }

      @include mq_639{
        display: none;
      }
      
      @include dark{
        color: $color-accent-white;
      }

      .icon{
        position: relative;
        top: -2px;
      }
    }

    &_prev,
    &_next{
      @include mq_639{
        display: inline-block;
      }
    }
  }
}

.card-explore{
  &__preview{
    height: 256px;
    position: relative;
    padding-bottom: calc(6/6*100%);
    background-color: $color-accent-gray-light;
    box-shadow: inset 0 0 0 2px rgba(0,0,0,0.03);
    
    @include dark{
      background-color: $color-accent-white;
    }
    iframe,
    img{
      position: absolute;
      height: auto;
      width: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }

    iframe{
      height: 100%;
      top: auto;
    }
  }

  &__button{
    white-space: nowrap;
  }

  &__foot{
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__bid{
    margin-top: 8px;
    a{
      color: $color-accent-gray-light;
      transition: color .2s;
      font-weight: 700;

      &:hover,
      &:focus{
        color: $color-accent-primary;
      }
    }
  }

  // &:hover,
  // &:focus{
  //   .card__preview img{
  //     transform: scale(1.1);
  //   }
  // }
}
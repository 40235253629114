.no-margin{
  &__top{
    margin-top: 0 !important;
  }
  &__bottom{
    margin-bottom: 0 !important;
  }
  &__left{
    margin-left: 0 !important;
  }
  &__right{
    margin-right: 0 !important;
  }
}

$spacing: (
  '4': 4px,
  '8': 8px,
  '16': 16px,
  '24': 24px,
  '32': 32px,
);

@each $state, $property-class in $spacing {
  .mb__#{$state}{
    margin-bottom: $property-class;
  }
  .ml__#{$state}{
    margin-left: $property-class;
  }
  .mr__#{$state}{
    margin-right: $property-class;
  }
  .mt__#{$state}{
    margin-top: $property-class;
  }
}
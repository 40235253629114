@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;700&family=Roboto+Mono:wght@700&display=swap');

@import './_variables';
@import './media-queries';
@import './root';

html{
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  -webkit-text-rendering: optimizeSpeed;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-kerning: none;
          font-kerning: none;
}

body,
html{
  height: 100%;
}

html body{
  color: var(--color-grey);
  font-family: var(--font-body);
  font-size: var(--paragraph-max-font-size);
  line-height: var(--paragraph-line-height);
  background-color: var(--color-white);
  @include max1024{
    font-size: var(--paragraph-min-font-size);
  }
}

img, iframe{
  max-width: 100%;
}
iframe{
  width: 100%;
  border: none;
}
img{
  height: auto;
}

a{
  text-decoration: none;
  transition: ease .3s;

  &:hover{
    text-decoration: none;
  }
  &:focus{
    outline: none;
    text-decoration: none;
  }
}

h1, h2, h3, h4, h5, h6{
  font-weight: bold;
  font-family: var(--font-heading);
  color: var(--color-black);
  margin-top: 0;
}

// h4, .h4{
//   font-size: var(--h4-max-font-size);
// }

p{
  &:last-child{
    margin-bottom: 0;
  }
}
.details__info{
  p:not(:last-child){
    margin-bottom: 12px;
  }

  a{
    color: var(--color-black);
    font-weight: 600;

    &:hover,
    &:focus{
      color: #3772FF;
    }
  }
}

ul, ol{
  list-style-position: outside;
  list-style-type: square;
  margin: 0;
  padding: 0 0 0 20px;
}

%reset_list{
  list-style: none outside none;
  margin: 0;
  padding: 0;
}

.table-dashboard{
  &__content{
    th{
      font-family: var(--font-body);
      font-size: var(--paragraph-max-font-size);
      font-weight: 400 !important;
      color: var(--color-grey);

      @include max1024{
        font-size: var(--paragraph-min-font-size);
      }
    }

  }
}

.site-socmed{
  span{
    display: inline-block;
    margin-right: 30px;

    &:last-child{
      margin-right: 0;
    }
  }
  svg,
  svg path{
    transition: ease .3s;
  }
  a{
    display: block;

    &:hover,
    &:focus{
      svg{
        path{
          fill: var(--color-black-secondary);
        }
      }
    }
  }
}

// Custom container

// Custom grid
.investhub{
  &-row{
    margin-left: -20px;
    margin-right: -20px;

    @include max1024{
      margin-left: -16px;
      margin-right: -16px;
    }
  }

  &-column{
    padding-left: 20px;
    padding-right: 20px;

    @include max1024{
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

@import './utils';

@import './overwrites';

@import './pages';

@import './components';

.page-body  {
  margin: 2em 0;
}

.page-type-btn{
  box-shadow: 0 10px 50px rgba(111, 168, 255, 0.15);
  display: block;
  padding: 30px 20px;
  border: 1px solid rgba(111, 168, 255, 0.35);
  border-radius: 10px;
  background-color: rgb(255, 255, 255);


  &:hover {
    border: 1px solid rgba(111, 168, 255, 0.85);
    background-color: rgba(111, 168, 255, 0.1);
  }

  .page-type-btn{
    &__icon svg{
      font-size: 60px;
    }

    &__text{
      font-size: 1.2em;
    }
  }
}

.button-tile {
  display: block;
  height: auto;
  width: 100%;
  padding:  20px;

  .tile__img, .tile__text {
    display: block;
    text-align: center;
  }

  .tile__img {
    margin-bottom: 10px;
  }
}

.create__line {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #EFEFEF;
}

.create__line.remove__line {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.create__info {
  margin-right: auto;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71429;
  margin-bottom: 0;
}

a {
  color: #3772ff;
}

.card__information {
  padding: 24px;
  border-radius: 12px;
}
.card__information_title {
  margin-bottom: 16px;
  color: #ffffff;
}
.card__information_content {
  font-weight: 500;
  color: #ffffff;
  line-height: 1.6;
}
.card__information_content p + p {
  margin-top: 16px;
}
.card__information_content ul {
  list-style: circle;
  padding-left: 16px;
}
.card__information_content ul li + li {
  margin-top: 12px;
}
.card__information_content ul li ul {
  margin-top: 12px;
}
.card__information_content a {
  color: #ffffff;
  text-decoration: underline;
  font-weight: 600;
}
.card__information_content a:hover, .card__information_content a:focus {
  text-decoration: none;
}

.card__information-primary {
  background-color: #2A85FF;
}

/* Switch */
.create__line-kyc .switch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-flow: row-reverse;
          flex-flow: row-reverse;
  width: 100%;
}
.create__line-kyc .switch .switch__inner {
  margin-left: 16px;
}
.create__line-kyc.create__line_left .switch__box {
  top: 2px;
}
.create__line-kyc.create__line_left .switch {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  margin-left: 0;
}
.create__line-kyc.create__line_left .switch .switch__inner {
  margin-left: 0;
  margin-right: 16px;
}

.create__line.remove__line {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.create__line.remove__line + .remove__line {
  margin-top: 16px;
}

.create__line-gray .create__info {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #6F767E;
  -webkit-transition: all .2s;
  transition: all .2s;
  line-height: 1.6;
}
.create__line-gray.create__line-kyc .switch__input:checked ~ .create__info {
  color: #1A1D1F;
}
body.kyc-body.dark-mode .create__line-gray.create__line-kyc .switch__input:checked ~ .create__info {
  color: #FCFCFC;
}

.settings .create__line-kyc .switch {
  margin-left: 0;
  width: 100%;
}

@media only screen and (max-width: 1558px) {
  .create__list_logevent .checkbox {
    -ms-flex-preferred-size: calc(50% - 16px);
        flex-basis: calc(50% - 16px);
    width: calc(50% - 16px);
  }
}
@media only screen and (max-width: 1023px) {
  .create__list_logevent .checkbox {
    -ms-flex-preferred-size: calc(100% - 16px);
        flex-basis: calc(100% - 16px);
    width: calc(100% - 16px);
  }
}
@media only screen and (max-width: 639px) {
  .create__list_logevent .checkbox__text {
    font-size: 13px;
  }
}
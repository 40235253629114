%project_check_uncheck{
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  margin: 0;
}
.project{

  &__center{
    flex-flow: row wrap;
  }

  &__fullwidth{
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 32px;
  }

  &__categories{
    z-index: 1;
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 16px;
  }
  
  &__video-wrapper{
    position: relative;
    width: 100%;
    height: 768px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding-bottom: calc(6/7*100%);
    background-color: $color-accent-gray;
    border-radius: 16px;
    overflow: hidden;

    iframe{
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &__logo{
    position: relative;
    margin-bottom: 24px;
    width: 120px;

    img{
      width: 100%;
      height: auto;
    }
  }

  &__options-list{
    min-width: 208px;
    justify-content: center;
  }

  &__actions-item{
    width: 100%;

    &.error{
      color: $color-accent-gray-dark;
      cursor: not-allowed;
    }
  }

  &__checked{
    color: $color-accent-green !important;
    @extend %project_check_uncheck;
    svg{
      fill: $color-accent-green !important;
    }
    .icon{
      width: 14px;
      height: 14px;
    }
  }

  &__unchecked{
    color: $color-accent-red !important;
    @extend %project_check_uncheck;
    svg{
      fill: $color-accent-red !important;
    }
    .icon{
      width: 12px;
      height: 12px;
    }
  }

  &__header{
    margin-bottom: 24px;
  }

  &__item{
    &:not(:last-child){
      margin-bottom: 54px;
    }
  }
}
.field__checkbox{
  // combine with material UI
  &.MuiFormControlLabel-root{
    // reset
    // align-items: baseline;
    .MuiButtonBase-root{
      padding: 0;
      // top: 10px;
    }
    .MuiIconButton-label{
      display: none;
    }

    // checkbox
    .MuiCheckbox-root{
      position: relative;
      width: 24px;
      height: 24px;
      margin-right: 12px;
      border-radius: 4px;
      border: 2px solid $color-accent-gray;
      transition: all .2s;
      
      @include dark(){
        background: $color-accent-dark-second;
        border-color: $color-accent-dark;
      }

      &:before{
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 14px;
        height: 10px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='10' fill='none' viewBox='0 0 14 10'%3E%3Cpath fill-rule='evenodd' d='M13.707.293a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L5 7.586 12.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfd'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto;
        opacity: 0;
        transition: opacity .2s;
      }

      // checked
      &.Mui-checked{
        background: $color-accent-primary;
        border-color: $color-accent-primary;
        &:before{
          opacity: 1;
        }
      }
    }

    // label
    .MuiFormControlLabel-label{
      line-height: 1.71429;
      font-weight: 500;
      color: $color-accent-dark-second;
      font-family: $font_dmsans;
      font-size: 14px;
      text-align: left;

      @include darkOutside(){
        color: $color-accent-dark-light;
      }


      a{
        color: $color-accent-dark-light;
        transition: color .2s;
        font-weight: 700;

        &:hover,
        &:focus{
          color: $color-accent-primary;
        }
      }
      .link{
        color: #3772FF;

        &:hover,
        &:focus{
          color: #044eff;
        }
      }
    }
  }

  &-wrapper{
    display: flex;
    flex-flow: row wrap;
    width: 100%;
  }
  &-vertical{
    .field__checkbox{
      width: initial;
      margin-bottom: 16px !important;
      margin-right: 16px;
    }
  }
}
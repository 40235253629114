.bg{
    &-white{
      background-color: white;
    }
    &-black{
      background-color: var(--color-black);
    }
    &-grey{
      background-color: var(--color-grey);
    }
    &-blue{
      background-color: var(--color-blue);
    }
  }
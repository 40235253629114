.nav-child{
  &__list{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 -16px;
  }

  &__group{
    flex: 0 0 calc(25% - 32px);
    width: calc(25% - 32px);
    margin: 0 16px;

    &:not(:last-child){
      margin-bottom: 32px;
    }

    &-inner{
      padding: 16px;
      transition: background .2s;
      display: block;
      border-radius: 12px;
      &:hover,
      &:focus{
        background-color: $color-accent-white-dark;

        @include dark(){
          background-color: $color-accent-dark-second;
        }
      }
    }

    &-unclickable{
      background-color: transparent !important;
    }
  }

  &__head{
    color: $color-accent-primary;
    font-weight: 600;
    margin-bottom: 16px;
  }

  &__body{
    color: $color-accent-dark-light;
  }
}
.onboarding-best{
  &__item:not(:last-child){
    margin-bottom: 32px;
  }

  &__info{
    margin-bottom: 24px;
  }

  &__list-v2{
    .onboarding-best__item{
      margin-bottom: 0;

      &:not(:last-child){
        @include mq_767{
          margin-bottom: 32px;
        }
      }
    }
  }
}
.site-main__dashboard-nav+.site-main__dashboard-content {
  padding-top: 42px;
}

.site-main__dashboard{
  &-title{
    font-size: var(--h2-max-font-size);

    @include max1024{
      font-size: var(--h1-min-font-size);
    }
  }
}
.partners{
  &__center{
    @include mq_1023{
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__list{
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    align-items: center;
    margin: 0 -12px;

    @include mq_1023{
      margin: 0;
      flex-flow: row nowrap;
      scroll-snap-type: x proximity;
      overflow-x: auto;
      width: 100%;
      padding-bottom: 16px;
    }
  }

  &__item{
    flex: 0 0 calc(14.257% - 24px);
    width: calc(14.257% - 24px);
    margin: 0 12px;

    @include mq_1023{
      scroll-snap-align: center;
      width: initial;
      min-width: 81px;
      margin: 0;
      justify-content: flex-start;
      align-items: center;
      flex-basis: initial;
    }

    &:not(:last-child){
      @include mq_1023{
        margin-right: 24px;
      }
    }

    img{
      max-height: 45px;

      @include mq_1023{
        width: auto;
        height: auto;
        max-width: initial;
        width: 100%;
        object-fit: contain;
      }
    }

    .img{
      &-light__mode{
        @include dark{
          display: none;
        }
      }

      &-dark__mode{
        display: none;

        @include dark{
          display: block;
        }
      }
    }
  }
  &__inner{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 8px 16px rgba(31, 48, 73, 0.12);
    border-radiuS: 8px;
    padding: 12px;
    min-height: 74px;

    @include mq_1023{
      max-width: 240px;
      width: 100%;
      min-width: 140px;
    }

    @include dark{
      background-color: $color-accent-dark-second;
    }
  }
}
.tab-custom{
  &__header{
    display: inline-block;
    border-bottom: 1px solid #353945;
    &-item{
      display: inlne-flex;
      position: relative;
      font-family: var(--font-2);
      font-size: 18px;
      line-height: 1.5;
      font-weight: 600;
      padding-bottom: 12px;
      color: #777E90;
      border-bottom: 2px solid transparent;
      margin-bottom: -1px;
  
      &:not(:last-child){
        margin-right: 24px;
      }
  
      &.active{
        color: #FBBE90;
        border-bottom-color: #FBBE90;
      }
    }
  }

  &__content{
    margin-top: 40px;
  }
}

.investhub-tab{
  text-align: center;
  // Tab List
  [role="tablist"]{
    display: inline-flex;
    border-bottom: 1px solid var(--color-border-dark);
  }
  .MuiTabs-indicator{
    background-color: #FBBE90;
  }
  // Tab Item
  [role="tab"]{
    position: relative;
    font-family: var(--font-2) !important;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
    padding-bottom: 12px;
    color: var(--color-gold);
    border-bottom: 2px solid transparent;
    margin-bottom: -1px;
    text-transform: none;
    min-width: initial;
    padding-left: 8px;
    padding-right: 8px;
    min-height: 40px;

    &:not(:last-child){
      margin-right: 24px;
    }

    &.Mui-selected{
      color: #FBBE90;
    }
  }

  ~ [role="tabpanel"]{
    padding-top: 64px;

    @media only screen and (max-width: 1023px) {
      padding-top: 32px;
    }
  }
}
/* ------------------------------------------------------------------- 
 * ## Wrapper
 * ------------------------------------------------------------------- */
 .stepper__wrapper{
  /*  
   * ### This class for resetting material stepper
   **/
  &.MuiStepper-root{
    background: transparent;
    padding: 0;

    .stepper__item{
      &:first-child{
        padding-left: 0;
      }
      &:last-child{
        padding-right: 0;
      }
    }
  }

  /*  
   * ### Connector
   **/
  .MuiStepConnector-root{
    .MuiStepConnector-line{
      border-color: #272B30;
    }
  }
 }

 /* ------------------------------------------------------------------- 
  * ## Items
  * ------------------------------------------------------------------- */
.stepper__item{
  /*  
   * ## Icon
   **/
  .MuiSvgIcon-root{
    color: white;

    &.MuiStepIcon-active{
      color: #3772FF;

      .MuiStepIcon-text{
        fill: white;
      }
    }

    &.MuiStepIcon-completed{
      color: #45B26B;

      .MuiStepIcon-text{
        fill: white;
      }
    }
  }
  .MuiStepIcon-text{
    font-family: var(--font-1);
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    fill: #23262F;
  }
  /*  
   * ## Text
   **/
  .MuiTypography-root{
    font-family: var(--font-1);
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    
    &.MuiStepLabel-completed,
    &.MuiStepLabel-active{
      color: white;
    }


  }
}
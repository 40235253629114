.MuiTooltip-popper{
  > * {
    font-family: $font_dmsans !important;
    font-size: 12px !important;
    background-color: rgba($color-accent-dark-second, .9) !important;

    @include darkOutside(){
      background-color: rgba($color-accent-gray-light, .9) !important;
      color: $color-accent-dark-second !important;
    }
  }
}
.hero{
  overflow: hidden;
  padding-top: 180px !important;
  padding-bottom: 0 !important;

  &__center{
    position: relative;
    padding-bottom: 42px;
  }

  &__content{
    position: relative;
    z-index: 3;
    max-width: 700px;

    @media only screen and (max-width: 1179px){
      max-width: 100%;
      text-align: center;
    }
  }

  &__title{
    color: white;
    font-size: 64px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: -.02em;
    margin-bottom: 24px;

    @media only screen and (max-width: 767px){
      font-size: 48px;
    }
  }

  &__text{
    color: white;
    margin-bottom: 42px;
    font-family: var(--font-2);
    font-size: 16px;
    line-height: 1.5;

    max-width: 560px;

    @media only screen and (max-width: 1179px){
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__buttons{
    @media only screen and (max-width: 967px) {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
    }
    a{
      &:not(:last-child){
        margin-right: 12px;

        @media only screen and (max-width: 967px){
          margin-bottom: 16px;
          margin-right: 0;
        }
      }

      @media only screen and (max-width: 967px){
        min-width: 275px;
      }
    }
  }

  &__statistic{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    margin-top: 40px;

    @media only screen and (max-width: 1179px){
      justify-content: center;
    }

    @media only screen and (max-width: 767px){
      flex-flow: row wrap;
      justify-content: center;
    }
  }

  &__statistic_group{
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-top: 24px;
    gap: 8px;
  }
  &__statistic_top,
  &__statistic_bottom{
    width: 100%;
  }
  &__statistic_top{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    @media only screen and (max-width: 1179px){
      justify-content: center;
      gap: 16px;
    }
  }
  &__statistic_bottom{
    @media only screen and (max-width: 1179px){
      justify-content: center;
    }
  }

  &__item{
    display: flex;
    flex-flow: column nowrap;
    gap: 2px;
  }

  &__double_item{
    display: flex;
    flex-flow: row nowrap;
    gap: 2px;
  }

  &__number{
    font-size: 32px;
    line-height: 1.33333;
    font-weight: 600;
    letter-spacing: -.01em;
    color: white;

    @media only screen and (max-width: 767px)  {
      font-size: 24px;
    }
  }

  &__total{
    color: white;
    font-family: var(--font-2);
    font-weight: 500;
  }

  &__partner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    margin-top: 80px;
    position: relative;
    z-index: 3;

    @media only screen and (max-width: 376px){
      margin-top: 48px;
    }

    @media only screen and (max-width: 1023px){
      flex-flow: row wrap;
      justify-content: center;
    }

    &_item{
      display: inline-block;
    }

    img{
      width: 100%;
      max-width: 150px;
      max-height: 45px;
      @media only screen and (max-width: 967px){
        max-width: 140px;
      }

      @media only screen and (max-width: 376px){
        max-width: 100px;
      }
    }
  }

  &__img{
    position: absolute;
    right: -20%;
    bottom: -18%;

    @media only screen and (max-width: 1179px){
      display: none;
    }
    img{
      width: 100%;
    }
  }
}
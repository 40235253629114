[class^="box-note"]{
  display: block;
  padding: 16px;
  border-radius: 12px;
  font-size: 14px;
  font-family: $font_poppins;
}

$note_styles: (
  blue: (
    background: lighten($color-accent-orange, 35%),
    text: $color-accent-primary,
  ),
  red: (
    background: lighten($color-accent-orange, 35%),
    text: $color-accent-red,
  ),
  orange: (
    background: lighten($color-accent-orange, 35%),
    text: $color-accent-orange,
  ),
  green: (
    background: lighten($color-accent-orange, 35%),
    text: $color-accent-green
  ),
  black: (
    background: lighten($color-accent-orange, 35%),
    text: $color-accent-dark-light
  )
);

@each $state, $property in $note_styles {
  $text: map-get($property, text);
  $background: map-get($property, background);

  .box-note__#{"" + $state}{
    color: $text;
    background: $background;
  }
}
.field-textarea{
  &.MuiFormControl-root{
    @extend %fieldResetSpace;
  }

  .MuiInputBase-root{
    @extend %fieldInputStyling;
    padding: 14px;

    textarea{
      font-weight: 500;
      font-size: 14px;
      font-family: $font_poppins !important;

      @include dark{
        color: $color-accent-white;
      }
    }
  }

}
.page{

  &-home{
    overflow-x: hidden;
    > svg{
      left: 0;
      top: 0;

      + .site-header{
        background: transparent;
      }
    }
  }

  &-sign-up,
  &-notfound{
    .site-main__breadcrumb{
      @include max1024{
        padding-top: 24px;
      }
    }
  }
}

@import './AdminSetting';

.page-main__article {
  margin-top: 35px;
  margin-bottom: 100px;

  @include max1024{
    margin-top: 34px;
    margin-bottom: 84px;
  }

  &-header {
    &-title {
      font-size: var(--h1-max-font-size);
      margin-bottom: var(--distance-min-xsmall);

      @include max1024{
        font-size: 38px;
        line-height: 1.4;
      }
    }
  }

  .list-number {
    list-style: none outside none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 8px;

      strong {
        font-weight: 600;
        color: var(--color-black-secondary);
      }
    }

    > li{
      + li{
        margin-top: 15px;
      }
    }

    ul {
      padding: 0 0 0 15px;
      margin: 6px 0;
      list-style: none outside none;

      ul{
        padding-left: 0;
        li{
          margin-bottom: 0;;
        }
        li + li{
          margin-top: 0;
        }
      }
    }
  }
}

.page-main__sign-up,
.page-main__notfound{
  margin-top: 35px;
  margin-bottom: 100px;

  &-inner{
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0 50px rgba(#6FA8FF, .15);
    background: white;
    padding: 70px 135px;
    border-radius: 10px;

    @include max768{
      width: 100%;
      padding: 38px 42px;
    }

    @include max578{
      padding: 24px;
    }
  }
}

.page-main__sign-up{
  &-header{
    text-align: center;
    margin-bottom: 34px;
    h1{
      font-size: var(--h1-min-font-size);
      margin-bottom: 0;
    }
    p{
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  // layout for the form
  form{
    > .MuiGrid-container{
      margin-bottom: -20px;
    }

    .MuiGrid-item{
      margin-bottom: 20px;
    }

  }

  .another-login{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;


    &__btn{
      width: 50%;

      @include max768{
        width: 100%;
      }
      &:first-child{
        padding-right: 10px;
        
        @include max768{
          padding-right: 0;
          padding-bottom: 12px;
        }
      }
      &:last-child{
        padding-left: 10px;

        @include max768{
          padding-left: 0;
        }
      }
    }
    .btn{
      width: 100% !important;
      padding-left: 12px;
      padding-right: 12px;
      border-radius: 4px;
      color: white;
      box-shadow: none !important;

      svg{
        position: relative;
        display: inline-block;
        top: 3px;
        margin-right: 8px;
      }

      &-facebook{
        background-color: #3D5B96;
      }
      &-google{
        background-color: #DD4D3F;
      }
    }
  }
}

.page-main__notfound{
  text-align: center;

  h1{
    font-size: var(--text-largest);
    line-height: 1.2;
    margin: 0 0 20px;

    @include max578{
      font-size: var(--h1-max-font-size);
    }
  }

  .btn{
    min-width: 260px;
    text-align: center;
    margin-top: 10px;
  }
}
.change-log{
  &__item{
    // padding: 0;
    cursor: initial;

    &::before,
    &::after{
      display: none;
    }

    .flex{
      @include mq_767{
        flex-flow: column;
      }
      .activity__date{
        flex: 1;

        @include mq_767{
          margin-right: 0;
          width: 100%;
        }
      }
    }
  }

  &__title{
    @include mq_767{
      font-size: 18px;
      font-weight: 500;
    }
  }
}
// Card Blog
.card-blog {
  position: relative;
  display: block;
  transition: all .3s ease;
  box-shadow: 0 0 50px rgba(111, 168, 255, .15);
  overflow: hidden;
  border-radius: 10px;

  &:hover,
  &:focus {
    box-shadow: 0 0 50px rgba(0, 102, 255, .20);
  }

  &__img {
    position: relative;
    height: 260px;
    overflow: hidden;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    img {
      height: 260px;
      width: 100%;
      object-fit: cover;
      position: relative;
    }
  }

  &__category {
    position: absolute;
    z-index: 2;
    left: 24px;
    bottom: 24px;
    margin: 0;

    &:hover,
    &:focus {
      color: white;
      background-color: var(--color-blue);
      border-color: var(--color-blue);
    }
  }

  &__contents {
    padding: 24px;
  }

  &__title {
    font-weight: 300;
    font-size: var(--h3-min-font-size);
    line-height: 1.6;
    margin-bottom: 24px;

    @include max768 {
      font-size: 18px;
    }

    a {
      display: inline-block;
      color: var(--color-black);

      &:hover,
      &:focus {
        color: var(--color-blue);
      }
    }
  }

  &__meta {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
  }

  &__love,
  &__time {
    svg {
      position: relative;
      display: inline-block;
      margin-right: 6px;
      top: 3px;
    }
  }

  &__love {}

  &__time {
    svg {
      top: 2px;
    }
  }

}
.thingslearn{
  position: relative;
  padding-top: 0 !important;

  &__bg{
    position: absolute;
    top: -60%;
    width: 100%;
    height: 100%;
    min-height: 971px;
  }

  &__center{
    position: relative;
    z-index: 1;
  }
  &__title{
    color: var(--color-yellow);
    margin-bottom: 16px;

    @media only screen and (max-width: 1023px){
      font-size: 42px !important;
    }

    @media only screen and (max-width: 767px){
      margin-bottom: 8px;
      font-size: 36px !important;
    }
  }

  &__text{
    font-family: var(--font-2);
    font-size: 16px;
    line-height: 1.5;
    color: white;
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 64px;
    text-align: center;

    @media only screen and (max-width: 1023px){
      margin-bottom: 32px;
    }
  }

  &__list{
    // display: flex;
    // flex-flow: row nowrap;
    // justify-content: center;
    margin: -32px -12px 0;
    text-align: center;

    @media only screen and (max-width: 767px){
      margin: 12px 8px 0;
    }
  }

  &__item{
    margin: 32px 12px 0;
    background-color: white;
    padding: 8px 12px;
    font-family: var(--font-2);
    display: inline-flex;
    justify-content: center;
    gap: 12px;
    border-radius: 4px;

    @media only screen and (max-width: 767px){
      border-radius: 4px;
      gap: 8px;
      margin: 12px 8px 0;
    }

    span + span{
      display: inline-block;
      font-weight: 600;
      position: relative;
      top: 4px;

      @media only screen and (max-width: 767px){
        top: 1px;
      }
    }
  }

  &__item_icon{
    background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='17.5' cy='17.5' r='17.5' fill='%23F1F5F9'/%3E%3Cpath d='M11 19L15 23L25 13' stroke='%230F172A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 32px 32px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-block;

    @media only screen and (max-width: 767px){
      background-size: 24px 24px;
      width: 24px;
      height: 24px;
    }

    @media only screen and (max-width: 376px){
      display: none;
    }
  }
}
.checkboxWrapper{
	:global{
		input[type="checkbox"] {
			&:checked {
					+ span span {
						border-color: #0052ff;
						background-color: #0052ff;

						&::after {
							opacity: 1;
						}
					}

					~ div.is-text-white{
						color: #fff;
					}
			}

			+ span span{
				position: relative;
				&::after{
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12'%3E%3Cpath d='M16.707.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0L.293 6.707A1 1 0 0 1 .735 5.02a1 1 0 0 1 .973.273l3.586 3.586a1 1 0 0 0 1.414 0L15.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfc' fill-rule='evenodd'/%3E%3C/svg%3E");
				}
			}

		}
	}
}
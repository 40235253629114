:root {
  --font-heading: 'Cabin', sans-serif;
  --font-body: 'Cabin', sans-serif;
  --font-amatic: 'Cabin', sans-serif;
  --font-roboto-mono: 'Roboto Mono', monospace;

  --color-white: #fff;
  --color-grey: #676666;
  --color-grey-secondary: #8F8F8F;
  --color-grey-thirdy: #E7E7E7;
  --color-black: #040405;
  --color-black-hover: #434348;
  --color-black-secondary: #0C0C0D;
  --color-blue: #0066FF;
  --color-blue-hover: #3683f6;
  --color-blue-light: #2196F3;
  --color-yellow-light: #FFFAF1;
  --color-red: #FF2323;
  --color-red-hover: #f74e4e;
  --color-text-placeholder: #8D98A4;
  --color-border: #E3E6F0;

  --distance-max-normal: 100px;
  --distance-max-large: 120px;
  --distance-min-normal: 80px;
  --distance-min-xsmall: 30px;

  --h1-max-font-size: 50px;
  --h1-min-font-size: 28px;

  --h2-max-font-size: 30px;

  --h3-min-font-size: 21px;
  --h4-max-font-size: 18px;

  --text-largest: 144px;
  --text-large: 62px;
  --paragraph-max-font-size: 16px;
  --paragraph-min-font-size: 14px;
  --paragraph-line-height: 1.8;

  --default-shadow: 0 10px 50px rgba(111,168,255, .15);
  --shadow-black-light: 0 0 10px rgb(0 0 0 / 15%);

  --default-radius: 33px;
  --radius-small: 4px;

  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --info-color: #{$info-color};
  --warning-color: #{$warning-color};
  --error-color: #{$error-color};
  --success-color: #{$success-color};
}

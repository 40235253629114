/*
 * Investhub Frontend Styles
 * ------------------------
 * Main stylesheet for Investhub Frontend (Latest Version)
 * Contains Tailwind configuration, base styles, and custom scrollbar styling
 * 
 * Start added: November 12, 2024
 */



/* class '.page-wrapper' just a trick to apply the effect only after page-wrapper */ 
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
    font-family: 'Public Sans', sans-serif;
}

/* Initial all tailwind or new version must inside `.page-wrapper`
-----------------------------------------------------------------*/
.page-wrapper {
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: 'Public Sans', sans-serif;
    font-size: 15px;
}

/* Scrollbar custom
-----------------------------------------------------------------*/

.scrollbar-custom {
    overflow: auto;
}
.scrollbar-custom::-webkit-scrollbar {
    width: 8px; /* Sesuaikan ukuran scrollbar */
    height: 8px; /* Sesuaikan ukuran horizontal scrollbar */
}
.scrollbar-custom::-webkit-scrollbar-track {
    background-color: initial;
}
.scrollbar-custom::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #2b2d33;
}
.scrollbar-custom::-webkit-scrollbar-thumb:active {
    background-color: #6F737E;
}
.scrollbar-custom::-webkit-scrollbar-thumb:hover {
    background-color: #6F737E;
}

/* Fields
-----------------------------------------------------------------*/
input[type=tel]:-webkit-autofill,
input[type=email]:-webkit-autofill,
input[type=tel]:-webkit-autofill:focus
input[type=email]:-webkit-autofill:focus,
input[type=tel]:-webkit-autofill:hover
input[type=email]:-webkit-autofill:hover{
    -webkit-text-fill-color: #fff;
    border: none;
    -webkit-box-shadow: none;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

/* Loader
-----------------------------------------------------------------*/
.page-wrapper .loader__wrapper{
	display: inline-flex;
	width: 24px;
	height: 24px;
}
.page-wrapper .loader__wrapper .loader{
    left: 50%;
    top: calc(50% - 1px);
    transform: scale(0.8);
}
.page-wrapper .loader__wrapper.loader__wrapper--center {
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}
.page-wrapper .loader__wrapper.loader__wrapper--center .loader{
    position: absolute;
    left: 16px;
    top: 16px;
}
.page-wrapper .loader {
	width: 4px;
	height: 4px;
	border-radius: 50%;
	position: relative;
	font-size: 4px;
	animation: loader 1.1s infinite ease;
	text-indent: -9999em;
	transform: translateZ(0);
}
.page-wrapper  .loader.is-large{
    transform: translateZ(0) scale(1.2);
}
.page-wrapper  .loader.is-xlarge{
    transform: translateZ(0) scale(1.5);
}


@keyframes loader {

	0%, 100% {
		box-shadow: 0 -2.6em 0 0 #fff, 1.8em -1.8em 0 0 hsla(0, 0%, 100%, .2), 2.5em 0 0 0 hsla(0, 0%, 100%, .2), 1.75em 1.75em 0 0 hsla(0, 0%, 100%, .2), 0 2.5em 0 0 hsla(0, 0%, 100%, .2), -1.8em 1.8em 0 0 hsla(0, 0%, 100%, .2), -2.6em 0 0 0 hsla(0, 0%, 100%, .5), -1.8em -1.8em 0 0 hsla(0, 0%, 100%, .7);
	}

	12.5% {
		box-shadow: 0 -2.6em 0 0 hsla(0, 0%, 100%, .7), 1.8em -1.8em 0 0 #fff, 2.5em 0 0 0 hsla(0, 0%, 100%, .2), 1.75em 1.75em 0 0 hsla(0, 0%, 100%, .2), 0 2.5em 0 0 hsla(0, 0%, 100%, .2), -1.8em 1.8em 0 0 hsla(0, 0%, 100%, .2), -2.6em 0 0 0 hsla(0, 0%, 100%, .2), -1.8em -1.8em 0 0 hsla(0, 0%, 100%, .5);
	}

	25% {
		box-shadow: 0 -2.6em 0 0 hsla(0, 0%, 100%, .5), 1.8em -1.8em 0 0 hsla(0, 0%, 100%, .7), 2.5em 0 0 0 #fff, 1.75em 1.75em 0 0 hsla(0, 0%, 100%, .2), 0 2.5em 0 0 hsla(0, 0%, 100%, .2), -1.8em 1.8em 0 0 hsla(0, 0%, 100%, .2), -2.6em 0 0 0 hsla(0, 0%, 100%, .2), -1.8em -1.8em 0 0 hsla(0, 0%, 100%, .2);
	}

	37.5% {
		box-shadow: 0 -2.6em 0 0 hsla(0, 0%, 100%, .2), 1.8em -1.8em 0 0 hsla(0, 0%, 100%, .5), 2.5em 0 0 0 hsla(0, 0%, 100%, .7), 1.75em 1.75em 0 0 #fff, 0 2.5em 0 0 hsla(0, 0%, 100%, .2), -1.8em 1.8em 0 0 hsla(0, 0%, 100%, .2), -2.6em 0 0 0 hsla(0, 0%, 100%, .2), -1.8em -1.8em 0 0 hsla(0, 0%, 100%, .2);
	}

	50% {
		box-shadow: 0 -2.6em 0 0 hsla(0, 0%, 100%, .2), 1.8em -1.8em 0 0 hsla(0, 0%, 100%, .2), 2.5em 0 0 0 hsla(0, 0%, 100%, .5), 1.75em 1.75em 0 0 hsla(0, 0%, 100%, .7), 0 2.5em 0 0 #fff, -1.8em 1.8em 0 0 hsla(0, 0%, 100%, .2), -2.6em 0 0 0 hsla(0, 0%, 100%, .2), -1.8em -1.8em 0 0 hsla(0, 0%, 100%, .2);
	}

	62.5% {
		box-shadow: 0 -2.6em 0 0 hsla(0, 0%, 100%, .2), 1.8em -1.8em 0 0 hsla(0, 0%, 100%, .2), 2.5em 0 0 0 hsla(0, 0%, 100%, .2), 1.75em 1.75em 0 0 hsla(0, 0%, 100%, .5), 0 2.5em 0 0 hsla(0, 0%, 100%, .7), -1.8em 1.8em 0 0 #fff, -2.6em 0 0 0 hsla(0, 0%, 100%, .2), -1.8em -1.8em 0 0 hsla(0, 0%, 100%, .2);
	}

	75% {
		box-shadow: 0 -2.6em 0 0 hsla(0, 0%, 100%, .2), 1.8em -1.8em 0 0 hsla(0, 0%, 100%, .2), 2.5em 0 0 0 hsla(0, 0%, 100%, .2), 1.75em 1.75em 0 0 hsla(0, 0%, 100%, .2), 0 2.5em 0 0 hsla(0, 0%, 100%, .5), -1.8em 1.8em 0 0 hsla(0, 0%, 100%, .7), -2.6em 0 0 0 #fff, -1.8em -1.8em 0 0 hsla(0, 0%, 100%, .2);
	}

	87.5% {
		box-shadow: 0 -2.6em 0 0 hsla(0, 0%, 100%, .2), 1.8em -1.8em 0 0 hsla(0, 0%, 100%, .2), 2.5em 0 0 0 hsla(0, 0%, 100%, .2), 1.75em 1.75em 0 0 hsla(0, 0%, 100%, .2), 0 2.5em 0 0 hsla(0, 0%, 100%, .2), -1.8em 1.8em 0 0 hsla(0, 0%, 100%, .5), -2.6em 0 0 0 hsla(0, 0%, 100%, .7), -1.8em -1.8em 0 0 #fff;
	}
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.3s ease-in;
}
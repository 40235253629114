// @import './GuestAppBar';
@import './site-header';
@import './site-footer';
@import './AdminNavigation';
@import './breadcrumb';
@import './dialog';
@import './stepper';
@import './Form';

// .site-main__sections{
//   @include max1024{
//     padding-top: 42px;
//   }
// }

@import  './dashboard';
@import 'card/card';

.section{
  padding-top: 85px;
  padding-bottom: 85px;

  &-header{
    &__title{
      font-size: var(--h1-max-font-size);
      line-height: 1.2;
      margin-bottom: 0;

      @include max1024{
        font-size: var(--h1-min-font-size);
      }
    }

    + .section-content{
      margin-top: 40px;

      @include max1024{
        margin-top: 28px;
      }
    }

    &__style{
      &-2,
      &-3{
        .section-header{
          &__title{
            flex: 1;
            font-size: var(--h2-max-font-size);

            @include max1024{
              font-size: var(--h1-min-font-size);
            }
          }

          // &__link{
          //   color: var(--color-black);
          //   svg{
          //     display: inline-block;
          //     margin-right: 6px;
          //     path{
          //       transition: all .3s ease;
          //     }
          //   }
          //   &:hover,
          //   &:focus{
          //     color: var(--color-blue);

          //     svg path{
          //       fill: var(--color-blue);
          //     }
          //   }
          // }
        }
      }
      &-2{
        display: flex;
        flex-flow: space-between;
        align-items: flex-end;
      }

      &-3{
        .section-header{
          &__title{
            font-size: var(--h2-max-font-size);
          }

          // &__filters{
          //   margin-top: 24px;
          //   width: 100%;
          //   display: flex;
          //   flex-flow: row wrap;
          //   justify-content: space-between;
          //   align-items: flex-end;

          // }

          // &__link{
          //   @media screen and (max-width: 528px) {
          //     display: none;
          //   }
          // }
        }

        // .filters{
        //   list-style: none outside none;
        //   margin: 0;
        //   padding: 0;
        //   li{
        //     display: inline-block;
        //     margin-right: 12px;
        //   }
        // }
      }
    }
  }

  // &-home{
  //   position: relative;
  //   text-align: center;

  //   @include max1024{
  //     padding-top: 0px;
  //   }
  //   &__content{
  //     max-width: 937px;
  //     margin-left: auto;
  //     margin-right: auto;
  //   }

  //   &__title{
  //     font-size: var(--text-large);
  //     line-height: 1.2;
  //     margin-bottom: 30px;

  //     @include max768{
  //       font-size: 68px;
  //     }
  //     @include max578{
  //       font-size: 58px;
  //     }
  //   }

  //   &__text{
  //     max-width: 700px;
  //     margin: 0 auto 30px;
  //     color: var(--color-black);
  //     font-size: var(--h3-min-font-size);

  //     @include max768{
  //       font-size: 18px;
  //     }
  //     @include max578{
  //       font-size: 16px;
  //     }
  //   }

  //   &__buttons{
  //     .btn{
  //       min-width: 160px;
  //       margin: 0 6px;
  //     }
  //   }
  // }

  &-deals{
    .section-content{
      margin: 0 -20px;

      @include max1024{
        margin-bottom: 32px;
      }
    }
    .slick{
      &-dots{
        display: none !important;
      }
      &-list{
        overflow: hidden;
      }
      &-slide{
        padding: 40px 20px 85px; // to make effect shadow show
      }
      // &-slider{
      //   overflow: hidden;
      // }
    }

    .slider-deals{
      &__arrow{
        height: 32px;
        width: 32px;
        background: white;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        z-index: 2;
        cursor: pointer;
        box-shadow: 0 0 10px rgba(0,0,0, .15);

        @media screen and (max-width: 1440px) {
          top: 35%;
        }

        @include max1024{
          top: initial;
          bottom: 24px;
          transform: translateY(0);
        }
      }

      &__prev{
        left: -42px;

        @media screen and (max-width: 1440px) {
          left: 24px;
        }
        @include max1024{
          left: 47%;
          transform: translateX(-50%);
        }
        @include max768{
          left: 45%;
        }
        @include max578{
          left: 43%;
        }
      }
      &__next{
        right: -42px;

        @media screen and (max-width: 1440px) {
          right: 24px;
        }
        @include max1024{
          right: 47%;
          transform: translateX(50%);
        }
        @include max768{
          right: 45%;
        }
        @include max578{
          right: 43%;
        }
      }

    }
  }
}
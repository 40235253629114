.site-main__breadcrumb{
    padding-top: 25px;
    padding-bottom: 45px;
  
    @include max1024{
      padding-top: 0;
      padding-bottom: 0;
    }
    &-inner{
      display: block;
  
      > *{
        display: inline-block;
        margin-right: 8px;
      }
  
      a,
      span{
        color: var(--color-grey);
      }
  
      a{
        &:hover,
        &:focus{
          color: var(--color-black-secondary);
        }
      }
    }
  
    &-sep{
      svg{
        height: 10px;
        width: 10px;
      }
    }
  }
.datarooms{
  &__list{
    display: flex;
    flex-flow: row wrap;
    margin: 0 -16px;
  }
  &__item{
    flex: 0 0 calc(33.333% - 32px);
    max-width: calc(33.333% - 32px);
    &:not(:last-child){
      margin-bottom: 32px;
    }
  }

  &__preview{
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: $color-accent-gray;

    padding-bottom: calc(6/7*100%);

    img{
      display: block;
      position: absolute;
      height: auto;
      width: 95%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }
  }
}
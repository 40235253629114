$primary-color: #0066FF;
$primary-color-dark: #102C75;
$primary-color-light: #4665B9;

$secondary-color: #C59F70;
$secondary-color-dark: #896F4E;
$secondary-color-light: #D0B28C;

$warning-color: #FF9800;
$warning-color-dark: #F57C00;
$warning-color-light: #FFB74D;

$error-color: #F44336;
$error-color-dark: #D32F2F;
$error-color-light: #E57373;

$info-color: #2196F3;
$info-color-dark: #1976D2;
$info-color-light: #64B5F6;

$success-color: #4CAF50;
$success-color-dark: #388E3C;
$success-color-light: #81C784;
.investhub-homedeals{
  &.section{
    padding-top: 0;
  }

  // slides
  &__slider{
    @include mq_1023{
      padding-bottom: 80px;
    }
  }
  .slick{
    &-list{
      height: initial;
    }
    &-arrow{
      top: 50%;
      transform: translateY(-50%);
      line-height: 40px;
      text-align: center;
      cursor: pointer;

      @include mq_1023{
        top: auto;
        bottom: -60px;
      }
    }

    &-next{
      right: -60px;
      @include mq_1339{
        right: -40px;
      }
      @include mq_1023{
        right: calc(50% - 56px);
      }
    }
    &-prev{
      left: -60px;
      @include mq_1339{
        left: -40px;
      }
      @include mq_1023{
        left: calc(50% - 56px);
      }
    }
  }

  .card{
    &__line{
      min-width: 200px;
    }
  }

  .discover__btns{
    @include mq_1023{
      margin-top: 72px;
    }
  }
}
// this MUST using material dialog
.investhub-modal{
  // overlay
  .MuiBackdrop-root{
    background-color: rgba(20, 20, 22, 0.9);
  }

  // size
  &__xxxl{
    .MuiDialog-paper{
      max-width: 95vw;
      width: 100%;
    }
  }
  &__sm{
    .MuiDialog-paper{
      max-width: 448px;
      width: 100%;
    }
    .popup{
      min-width: 448px;
    }
  }
  &__l{
    .MuiDialog-paper{
      max-width: 1023px;
      width: 100%;

      @include mq_1179{
        max-width: 90%;
      }
    }
    .popup{
      min-width: 1023px;

      @include mq_1179{
        min-width: initial;
      }
    }
  }
  &__m{
    .MuiDialog-paper{
      max-width: 639px;
      width: 100%;

      @include mq_767{
        max-width: 90%;
      }
    }
    .popup{
      min-width: 639px;

      @include mq_767{
        min-width: initial;
      }
    }

  }

  // container paper
  .MuiDialog-paper{
    background: $color-accent-gray-light;
    border-radius: 16px;
    margin: 0;

    @include darkOutside{
      background: $color-accent-dark-dark;
    }

    .popup{
      margin: 0;
      max-width: 100%;
      width: 100%;
    }
  }

  &__video{
    .MuiDialog-paper{
      border-radius: 12px;
    }
    .investhub-popup{
      padding: 0;
      min-width: initial;
      overflow: hidden;
      border-radius: 0;
      background-color: black;
    }

    .popup__close{
      display: none;
    }

    .react-play__ratio{
      width: 100% !important;
      max-width: 640px !important;
      padding-bottom: calc(6/7*100%);
      border-radius: 12px;

      > div{
        display: block;
        position: absolute;
        height: auto;
        width: 90%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
    }
  }
}

.investhub-popup{
  overflow-x: hidden;
  &__close{
    position: absolute;
    top: 32px;
    right: 32px;
    width: 40px;
    height: 40px;
    border: 2px solid #E6E8EC;
    border-radius: 50%;
    line-height: normal;
    opacity: 1;
    transition: all .2s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover,
    &:focus{
      border-color: $color-accent-dark-second;
    }

    svg{
      width: 14px;
      height: 14px;

      @include darkOutside{
        fill: #E6E8EC;
      }
    }
  }
}
.investhub-blog{
  border-top: 1px solid $color-accent-gray;
  
  @include dark{
    border-color: $color-accent-dark;
  }

  &__center{
    max-width: 1280px;
    margin: 0 auto;
  }

  &__item{
    flex-basis: calc(33.333% - 32px);
    width: calc(33.333% - 32px);

    @include mq_767{
      width: 100%;
      max-width: 478px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__preview{
    position: relative;
  }

  &__category{
    z-index: 2;
    position: absolute;
    left: 16px;
    top: 16px;
  }

  &__date{
    margin-bottom: 40px;
    display: block;
    font-size: 12px;
    line-height: 1.66667;
    color: $color-accent-dark-light;
  }

  &__note{
    margin-top: 42px;
    text-align: center;
  }
}
.sharelove{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;

  &__like{
    svg{
      margin: 0 8px 0 0 !important;
      fill: #ef466f !important;
    }
  }

  &__social{
    &:not(:last-child){
      margin-right: 8px;
    }
  }
}
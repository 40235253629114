// card upload
.investhub-upload{
  &__row{
    display: flex;
    justify-content: flex-start;
    flex-row: row wrap;
    margin: 0 -16px;

    @include mq_767{
      display: block;
      margin: 0;
    }
  }

  &__card{
    flex: 0 0 calc(33.333% - 32px);
    width: calc(33.333% - 32px);

    @include mq_767{
      width: 100%;
    }

    &:not(:last-child){
      margin-bottom: 32px;
    }

    .preview__inner{
      width: 100%;
    }
  }

}

.field-upload{
  // field upload preview
  &__preview{
    embed{
      width: 100%;
    }
  }
  &__link{
    padding-bottom: 0;
  }

  // field upload button
  &__component{}
  &__inner{
    position: relative;
  }
  &__dragzone{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 182px;
    margin-top: 16px;
    border-radius: 16px;
    overflow: hidden;
    background: $color-accent-white-dark;
  }
  &__button{
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .icon{
      width: 24px;
      height: 24px;
      fill: $color-accent-dark-light;
    }

    &-contained{
      // background-color: $color-accent-dark;
    }
  }
  &__format{
    font-size: 12px;
    line-height: 1.66667;
    color: $color-accent-dark-light;
    margin-top: 6px;
  }

  &__note{
    margin-top: 16px;
    font-size: 12px;
    a{
      color: $color-accent-primary;
      font-weight: 700;

      &:hover,
      &:focus{
        color: $color-accent-primary-dark;
      }
    }
  }
}
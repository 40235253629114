.footer {
  &__inner{
    border-top: 1px solid var(--color-border-dark);
    padding-top: 216px;
    background-color: var(--color-dark);
    color: var(--color-light);
  }
}

// #######################################
// Row
// #######################################
.footer__row {
  display: flex;
  padding: 80px 0 32px;
  // border-bottom: 1px solid var(--color-border-dark);
}

@media only screen and (max-width: 1023px) {
  .footer__row {
    display: block;
  }
}

// #######################################
// Col 1
// #######################################
.footer__col__one {
  flex-grow: 1;
  padding-right: 32px;
}

@media only screen and (max-width: 1023px) {
  .footer__col__one {
    position: relative;
    margin-bottom: 32px;
    padding: 0;
  }
}

.footer__col__one a {
  display: inline-block;
  margin-bottom: 8px;
}

.footer__col__one a img {
  width: 187px;
}

.footer__info {
  max-width: 310px;
  font-family: var(--font-2);
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -.01em;
  margin-bottom: 24px;

  @media only screen and (max-width: 1023px){
    max-width: 100%;
  }
}

// Socials
.footer__socials {
  display: flex;
  justify-content: flex-start;
  list-style: none outside none;
  padding: 0;
}

.social__item {
  &:not(:last-child) {
    margin-right: 16px;
  }

  a {
    margin-bottom: 0;

    &:hover {
      svg path {
        fill: var(--color-blue);
      }
    }
  }

  svg path {
    transition: all .2s ease-in-out;
  }
}

// #######################################
// Col 2
// #######################################
.footer__col__two {
  display: flex;
  flex-shrink: 0;
  width: 352px;
}

@media only screen and (max-width: 1179px) {
  .footer__col__two {
    width: 276px;
  }
}
@media only screen and (max-width: 1023px) {
  .footer__col__two {
    width: auto;
    margin-bottom: 32px;
    display: block;
    border-width: 1px 0;
    border-style: solid;
    border-color: var(--color-border-dark);
  }
}

@media only screen and (max-width: 767px) {
  .footer__col__two {

  }
}

// #######################################
// Col 3
// #######################################
.footer__col__three {
  flex-shrink: 0;
  width: 384px;
  padding-left: 32px;
}

@media only screen and (max-width: 1179px) {
  .footer__col__three {
    width: 304px;
  }
}

@media only screen and (max-width: 1023px) {
  .footer__col__three {
    width: 100%;
    padding: 0;
  }
}

// #######################################
// Group
// #######################################
.footer__group {
  flex: 0 0 50%;
  @media only screen and (max-width: 1023px) {
    flex: 0 0 100%;
  }
}

.footer__group:first-child {
  padding-right: 16px;
}

@media only screen and (max-width: 1023px) {
  .footer__group:first-child {
    padding-right: 0;
  }
}

.footer__group:nth-child(2) {
  padding-left: 16px;
}

@media only screen and (max-width: 1023px) {
  .footer__group:nth-child(2) {
    padding-left: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .footer__group:not(:last-child) {
    border-bottom: 1px solid var(--color-border-dark);
  }
}

// #######################################
// Head
// #######################################
.footer__head {
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  @media only screen and (max-width: 1023px) {
    margin-bottom: 0;
    cursor: pointer;
    position: relative;
    padding: 32px 32px 32px 0;
  }

  @media only screen and (max-width: 767px) {
    text-transform: uppercase;
    font-size: 12px;
    font-family: var(--font-1);
    line-height: 1;
    font-weight: 500;
  } 
}

.footer__head svg {
  display: none;
  @media only screen and (max-width: 1023px) {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 10px;
    width: 10px !important;
    height: 10px !important;
    transform: translateY(-50%);
    fill: #777E90;
    transition: transform .2s;
  }
}

.footer__head.active .icon {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

// #######################################
// Body
// #######################################
.footer__body{
  @media only screen and (max-width: 1023px) {
    padding-bottom: 32px;
  }

  // &_rwd{
  //   display: none;
  // }
}

// #######################################
// Menu
// #######################################
.footer__menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer__menu a,
.footer__menu span{
  font-family: var(--font-2);
  font-size: 14px;
  font-weight: 700;
  line-height: 1.14286;
  color: #777E90;
}

.footer__menu a {
  transition: color .2s;

  &:hover{
    color: var(--color-blue);
  }
}

.footer__menu *:not(:last-child) {
  margin-bottom: 24px;
}

// #######################################
// Category
// #######################################
.footer__category{
  margin-bottom: 40px;
  font-size: 16px;
  font-family: var(--font-1);
  line-height: 1.5;
  font-weight: 500;

  @media only screen and (max-width: 376px) {
    margin-bottom: 32px;
  }
}

// #######################################
// Text
// #######################################
.footer__text{
  font-family: var(--font-2);
  margin-bottom: 24px;
}

// #######################################
// Subscription
// #######################################
.subscription{
  position: relative;

  > div{
    width: 100%;
  }
}

.subscription__input{
  > div{
    width: 100%;
    &:before,
    &:after{
      display: none;
    }
  }

  input[type="email"]{
    width: 100%;
    height: 48px;
    padding: 0 48px 0 14px;
    border-radius: 24px;
    background: none;
    border: 2px solid var(--color-border-dark);
    font-family: var(--font-1);
    font-weight: 500;
    font-size: 14px;
    line-height: 1.71429;
    color: var(--color-light);
    transition: border-color .2s;

    &:focus{
      border-color: var(--color-border-focus);
    }

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::placeholder {
      color: #777E90;
    }
  }
}

.subscription__button{
  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--color-blue);
  transition: background .2s;

  svg{
    width: 14px;
    height: 14px;
    fill: #FCFCFD;
  }

  &:hover{
    background: var(--color-blue-dark);
  }
}

// #######################################
// Foot
// #######################################
.footer__foot {
  display: flex;
  justify-content: space-between;
  padding: 32px 0 40px;
  font-size: 12px;
  line-height: 1.66667;
  @media only screen and (max-width: 767px){
    display: block;
    padding: 24px 0 32px;
  }
}

// #######################################
// Copyright
// #######################################
.footer__copyright {
  color: #777E90;
  font-family: var(--font-1);
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .footer__copyright {
    text-align: center;
  }
}

// #######################################
// Note
// #######################################
.footer__note{
  color: #777E90;
  font-family: var(--font-1);
  font-weight: 500;
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .footer__note {
    // display: none;
    text-align: center;
    margin-top: 12px;
  }
}
.video__item{
  width: 100%;
  height: 367px;
  padding-bottom: calc(6/7*100%);
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  display: block;

  iframe{
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    position: absolute;
  }

  &-9-16{
    padding-bottom: calc(9/16*100%)
  }
}
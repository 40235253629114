$font_poppins: 'Poppins', sans-serif;
$font_dmsans: 'DM Sans', sans-serif;

$color-accent-white: #fff;
$color-accent-white-dark: #F4F5F6;
$color-accent-gray: #E6E8EC;
$color-accent-gray-light: #FCFCFD;
$color-accent-gray-dark: #B1B5C3;
$color-accent-dark: #353945;
$color-accent-dark-light: #777E90;
$color-accent-dark-dark: #141416;
$color-accent-dark-second: #23262F;
$color-accent-primary: #3772FF;
$color-accent-primary-dark: #044eff;
$color-accent-red: #EF466F;
$color-accent-red-hoverstate: #e41446;
$color-accent-orange: #ff613d;
$color-accent-green: #45B26B;

// not used
$shadow-light-mode: 0px 64px 64px -48px rgba(31,47,70, 0.12);
$shadow-large-light-mode: 0px 16px 64px 0 rgba(31,47,70, 0.40);
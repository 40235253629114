.tenant-details{

  &__top-full{
    max-width: 100%;
  }
  &__row{
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-left: -16px;
    margin-right: -16px;

    @include mq_1023{
      display: block;
    }

    &:not(:last-child){
      margin-bottom: 72px;

      @include mq_1023{
        margin-bottom: 32px;
      }
    }

    &-heading{
      &.faq__row{
        margin-bottom: 12px;
      }
    }
  }

  &__col{
    margin-left: 16px;
    margin-right: 16px;
    padding-left: 0 !important;
    padding-right: 0 !important;

    &:not(:last-child){
      @include mq_1023{
        margin-bottom: 40px;
      }
    }

    &:first-child{
      flex-basis: calc(40% - 32px) !important;
      width: calc(40% - 32px) !important;

      @include mq_1023{
        width: calc(100% - 32px) !important;
      }
      @include mq_767{
        display: block;
      }
    }

    &:last-child{
      flex-basis: calc(40% - 32px) !important;
      width: calc(40% - 32px) !important;

      @include mq_1023{
        width: calc(100% - 32px) !important;
      }
    }

    &.full{
      flex-basis: calc(100% - 32px) !important;
      width: calc(100% - 32px) !important;
    }
  }
}

.tenant-activity__center{
  .activity{
    &__filters{
      @include mq_1023{
        display: block;
        order: -1;
        margin-top: 40px;
      }
    }
  }

  // reset the button
  .item__info .button.w-full{
    @include mq_1023{
      width: initial;
      min-width: 120px;
    }
  }
}
// Token Offer
.card-token__offer {
  position: relative;

  &-inner {
    position: relative;
    display: block;
    transition: all .3s ease;
    box-shadow: 0 0 50px rgba(111, 168, 255, .15);
    overflow: hidden;
    border-radius: 10px;

    &:hover,
    &:focus {
      box-shadow: 0 0 50px rgba(0, 102, 255, .20);
    }
  }

  &-video {
    height: 100%;
  }

  &-img {
    position: relative;
    height: 260px;
    // overflow: hidden;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: white;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      position: relative;
    }

    .card-token__offer-img-logo{
      margin: 10px;
      max-width: 90%;
    }

    &-like {
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 2;
      background: white;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;

      svg path {
        stroke: #FF2323;
      }

      &.has-numbers {
        width: initial;
        border-radius: 33px;
        padding-left: 14px;
        padding-right: 14px;
        line-height: 32px;

        span {
          display: inline-block;
          line-height: 32px;
          color: var(--color-black);
          padding-left: 4px;
          font-size: var(--paragraph-min-font-size);
        }
      }
    }

    &-avatars {
      position: absolute;
      height: 32px;
      bottom: -16px;
      left: 16px;
      z-index: 2;

      .avatar {
        height: 32px;
        width: 32px;
        padding: 2px;
        background: white;
        position: relative;
        display: inline-block;
        border-radius: 50%;

        img {
          width: 28px;
          height: 28px;
          border-radius: 50%;
        }

        &-verified {
          position: absolute;
          background: var(--color-blue);
          width: 16px;
          height: 16px;
          bottom: 0;
          right: -6px;
          border: 2px solid white;
          display: inline-block;
          border-radius: 50%;

          svg {
            fill: white;
            position: relative;
            top: -10px;
            left: 3px;
          }
        }

        &:nth-child(2) {
          z-index: 3;
          left: -6px;
        }

        &:nth-child(3) {
          z-index: 4;
          left: -12px;
        }
      }
    }

    &-more {
      position: absolute;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 16px;
      bottom: -16px;
      z-index: 2;
      box-shadow: 0 0 10px rgba(0, 0, 0, .15);

      svg {
        position: relative;
        top: -3px;
      }
    }
  }

  &-infos {
    position: relative;
    padding: 32px 24px;
    font-size: var(--paragraph-min-font-size);
    color: var(--color-black);
  }

  &-title {
    font-size: var(--h4-max-font-size);
    font-weight: 500;
    margin: 0 0 16px;

    @include max768 {
      font-size: 16px;
    }
  }

  &-cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }

  &-bid {
    span {
      font-weight: 600;
      margin-right: 6px;
    }
  }
}
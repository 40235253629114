.section-faq{
  &__pricing{
    + .section-bg{
      background-color: initial;
    }
  }
}
.faq{
  #{&}__col{
    width: 100%;
  }
  // accordion
  &__head{
    @include dark(){
      color: $color-accent-white;
    }

    &:hover,
    &:focus{
      @include dark(){
        color: $color-accent-primary;
      }
    }
    &::before{
      display: none;
    }
  }
  &__content{
    font-size: 16px;
    line-height: 1.5;
    color: $color-accent-dark-light;

    a{
      color: $color-accent-primary;
      font-weight: 500;

      &:hover,
      &:focus{
        color: $color-accent-primary-dark;
      }
    }
  }
  .investhub-accordion{
    box-shadow: none;
    background: transparent;
    margin: 0 !important;
    border-top: 1px solid $color-accent-gray;
    border-radius: 0 !important;

    @include dark(){
      border-color: $color-accent-dark;
    }

    &::before{
      display: none;
    }

    .MuiAccordionSummary-content{
      margin: 0;
    }

    .MuiSvgIcon-root{
      width: 18px;
      height: 18px;

      @include dark(){
        fill: $color-accent-dark-light;
      }
    }

    &__summary,
    &__details{
      padding: 0;
    }
    &__details{
      padding-bottom: 32px;
      flex-flow: column nowrap;
      align-items: flex-start;
    }
  }
}

.faq ~ .section-bg{
  background: transparent;
}
%btn{
  display: inline-block;
  font-size: var(--paragraph-min-font-size);
  font-weight: 500;
  border-radius: 33px;
  padding: 8px 26px;
  min-width: 100px;
  text-align: center;

  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
  @media screen and (max-width: 678px) {
    font-size: 13px;
  }
}

%btn-blue{
  border: 1px solid var(--color-blue);
  background-color: var(--color-blue) !important;
  color: var(--color-white);

  &:hover,
  &:focus{
    color: var(--color-white);
    border: 1px solid var(--color-blue-hover);
    background-color: var(--color-blue-hover) !important;
  }
}

%btn-dark{
  border: 1px solid var(--color-black);
  background-color: var(--color-black) !important;
  color: var(--color-white);

  &:hover,
  &:focus{
    color: var(--color-white);
    border: 1px solid var(--color-black-hover) !important;
    background-color: var(--color-black-hover) !important;
  }
}

%btn-red{
  border: 1px solid var(--color-red);
  background-color: var(--color-red) !important;
  color: var(--color-white);

  &:hover,
  &:focus{
    border: 1px solid var(--color-red-hover);
    background-color: var(--color-red-hover) !important;
  }
}

%btn-blue__line{
  border: 1px solid var(--color-blue);
  background: transparent !important;
  color: var(--color-blue) !important;

  &:hover,
  &:focus{
    border: 1px solid var(--color-blue-hover);
    color: var(--color-blue-hover) !important;
  }
}

%btn-default{
  border: 1px solid #F2F4F6 !important;
  background-color: #F2F4F6 !important;
  color: #778ca2 !important;

  &:hover,
  &:focus{
    border: 1px solid var(--color-blue) !important;
    background-color: var(--color-blue) !important;
    color: var(--color-white) !important;
  }
}

.btn{
  @extend %btn;

  &-blue{
    @extend %btn-blue;

    &__line{
      @extend %btn-blue__line;

      &.btn-disabled{
        border-color: var(--color-grey);
        color: var(--color-grey);
        cursor: not-allowed;
      }
    }
  }

  &-dark{
    @extend %btn-dark;
  }
  
  &-red{
    @extend %btn-red;
  }

  &-default{
    @extend %btn-default;
  }

  &.MuiButton-textPrimary{
    @extend %btn-blue;
  }

  &.btn-large{
    padding: 12px 38px;
  }

  &.btn-small{
    padding: 4px 18px;
    font-size: 11px;
    min-width: initial;
  }

  &.btn-minwidth{
    min-width: 160px;
    text-align: center;
  }

  &-clear{
    &__uppercase{
      text-transform: none !important;
    }
    &__shadow{
      box-shadow: none !important;
    }
  }

  &-shadow{
    box-shadow: var(--default-shadow) !important;
  }
}

.MuiButton-root{
  &.btn{
    @extend %btn;

    &.Mui-disabled{
      cursor: not-allowed;
      background-color: var(--color-grey-thirdy) !important;
    }
  }
  
  &.btn-blue{
    @extend %btn-blue;
  }
  &.btn-blue__line{
    @extend %btn-blue__line;
  }
  &.btn-dark{
    @extend %btn-dark;

    &.Mui-disabled{
      border: 1px solid var(--color-grey-secondary) !important;
      background: var(--color-grey-secondary) !important;
      color: var(--color-black) !important;
      cursor: not-allowed;
    }
  }
  &.btn-red{
    @extend %btn-red;
  }
  &.MuiButton-containedPrimary.btn{
    @extend %btn-blue;

    &.Mui-disabled{
      color: var(--color-secondary) !important;
      border-color: var(--color-grey-thirdy) !important;
    }
  }
}

.btn-investhub,
.btn-investhub.MuiButton-root{
  padding: 12px 20px;
  box-shadow: none;
  margin-bottom: 0;
  text-transform: none;
}


.MuiButtonBase-root.MuiButton-root {
  margin-bottom: 0.5em;

  &:not(:last-child) {
    margin-right: 0.5em;
  }
}

.MuiButton-root.MuiButton-contained {
  font-weight: bold;

  &Primary {
    background-color: $primary-color;

    &:hover {
      background-color: $primary-color-dark;
    }
  }

  &Secondary {
    background-color: $secondary-color;

    &:hover {
      background-color: $secondary-color-dark;
    }
  }

  &Warning {
    background-color: $warning-color;
    color: white;

    &:hover {
      background-color: $warning-color-dark;
    }
  }

  &Error {
    background-color: $error-color;
    color: white;

    &:hover {
      background-color: $error-color-dark;
    }
  }

  &Info {
    background-color: $info-color;
    color: white;

    &:hover {
      background-color: $info-color-dark;
    }
  }

  &Success {
    background-color: $success-color;
    color: white;

    &:hover {
      background-color: $success-color-dark;
    }
  }
}

.MuiTablePagination-root .MuiButton-root {
  min-width: 45px;
}

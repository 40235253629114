.not-found{
  &__title{
    font-size: 14rem;

    @include mq_767{
      font-size: 7rem;
    }
  }

  &__button{
    margin-top: 80px;
    min-width: 140px;
    text-align: center;
  }
}
.MuiMenu-paper{
  border-radius: 12px !important;
  background-color: $color-accent-gray-light;
  border: 2px solid $color-accent-gray;
  box-shadow: 0 4px 12px rgba(35,38,47, 0.10) !important;

  @include darkOutside(){
    background-color: $color-accent-dark !important;
    box-shadow: 0 4px 12px rgba(20,20,22, 0.10) !important;
    border-color: $color-accent-dark;
  }

  .MuiList-root{
    padding: 0 !important;
  }

  // paper for dropdown / select
  .MuiMenuItem-root{
    font-family: $font_poppins !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: $color-accent-dark !important;
    background-color: $color-accent-gray-light !important;

    @include darkOutside(){
      color: $color-accent-white !important;
      background-color: $color-accent-dark !important;
    }

    &.Mui-selected, 
    &:hover,
    &:focus{
      color: $color-accent-dark !important;
      background-color: $color-accent-white-dark !important;

      @include darkOutside(){
        color: $color-accent-white !important;
        background-color: $color-accent-dark-second !important;
      }
    }

    &.Mui-selected{
      color: $color-accent-primary !important;
    }
  }
}
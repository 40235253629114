.cta{
  padding-bottom: 0 !important;
  &__center{
    max-width: 978px;
  }

  &__list{
    display: flex;
    margin: 0 -16px;

    @media only screen and (max-width: 767px){
      flex-flow: row wrap;
    }
  }

  &__item{
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 0 16px;
    padding: 40px;
    border-radius: 16px;
    border: 1px solid var(--color-gold);
    transition: border .2s;

    @media only screen and (max-width: 767px){
      flex: 0 0 calc(100% - 32px);
      width: calc(100% - 32px);
      padding: 32px;
      border-radius: 12px;
    }

    &:not(:last-child){
      @media only screen and (max-width: 767px){
        margin-bottom: 32px;
      }
    }

    &:hover,
    &:focus{
      border-color: #FBBE90;
    }

    &_title{
      color: white;
      margin-bottom: 24px;
    }

    &_list{
      list-style: none outside none;
      margin: 0 0 24px;
      padding: 0;
      li{
        color: white;
        font-family: var(--font-2);
        padding-left: 32px;
        position: relative;

        &:not(:last-child){
          margin-bottom: 8px;
        }

        &:before{
          content:"";
          position: absolute;
          display: inline-block;
          height: 18px;
          width: 18px;
          margin-right: 12px;
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9.5L5 14.5L14.5 1' stroke='white' stroke-width='2'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center center;

          left: 0;
          top: 2px;
        }
      }
    }
  }
}
$status_styles: (
  'blue': $color-accent-primary,
  'red': $color-accent-red,
  'orange': $color-accent-orange,
);

@each $state, $property in $status_styles {
  .status-#{$state}{
    background: $property;
  }
  .status-stroke-#{$state}{
    color: $property;
    box-shadow: inset 0 0 0 2px $property; 
  }
}
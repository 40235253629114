.field__link{
  a{
    line-height: 1.71429;
    font-weight: 700;
    color: $color-accent-dark-light;
    font-family: $font_dmsans;
    font-size: 14px;
    transition: color .2s;

    &:hover,
    &:focus{
      color: $color-accent-primary;
    }
  }
}
.single-page{
  &__col{
    &_full{
      flex-basis: calc(100% - 32px);
      width: calc(100% - 32px);
    }
  }

  p{
    &:not(:last-child){
      margin-bottom: 24px;
    }
  }

  .list-number{
    list-style-type: none;
    padding-left: 0;
    ul{
      list-style-type: none;

    }

    li{
      &:not(:last-child){
        margin-bottom: 16px;
      }

      ul{
        margin-top: 12px;
      }
    }

    + p{
      margin-top: 24px;
    }
  }
}
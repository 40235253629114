/* ------------------------------------------------------------------- 
 * ## Opportunity
 * ------------------------------------------------------------------- */
.opportunity{
  /*  
   * Table
   **/
  &-table{
    display: table;
    width: 100%;
    overflow: hidden;
    position: relative;

    &__row{
      display: table-row;

      &:not(:first-child){
        .opportunity-table__col{
          vertical-align: top;
        }
      }
    }

    &__col{
      display: table-cell;
      padding: 12px 16px;

      &:first-child{
        padding-left: 0;
      }
      &:last-child{
        padding-right: 0;
      }

      @media only screen and (max-width: 1023px) {
        display: flex;
        align-items: center;
        padding: 0;
      }

      @media only screen and (max-width: 767px) {
        display: block;
      }

      &:not(:last-child){
        @media only screen and (max-width: 767px) {
          margin-bottom: 16px;
        } 
      }
    }

    .item__action{
      max-width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  /*  
   * Progress
   **/
  &-progress{
    width: 100%;
    position: relative;
    margin-top: 32px;

    &__bar{
      position: relative;
      box-shadow: 0 0 0 2px #3772FF inset;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      padding: 0 24px;
      width: 100%;
      // background: #3772FF;
      border-radius: 24px;
      overflow: hidden;
      margin-bottom: 16px;

      span{
        background-color: #3772FF;
        height: 48px;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 24px;
      }
    }
  }

  /*  
   * Content
   **/
  &-content{
    display: block;
    margin-top: 72px;
    font-size: 16px;
    line-height: 1.5;
    color: #777E90;

    .item__title{
      margin-bottom: 24px;
    }

    * + .item__title{
      margin-top: 48px;
    }

    p{
      &:not(:last-child){
        margin-bottom: 16px;
      }
    }

    ol li{
      &:not(:last-child){
        margin-bottom: 12px;
      }
    }
  }

  // #######################################
  // Download & link
  // #######################################
  &-download,
  &-link{
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    color: #777E90;
    cursor: pointer;
    transition: color .2s;

    &:hover,
    &:focus{
      color: #040405; 

      @include dark{
        color: white;
      }
    }
  }

  /*  
   * Download
   **/
  &-download{
    span{
      margin-right: 24px;
    }
  }

  /*  
   * Other
   **/
  &-other{
    &__title{
      margin-bottom: 0;
    }

    &__inner{
      margin: 0 -16px;

      @media only screen and (max-width: 1179px) {
        margin-bottom: 40px;
      }

      .slick-initialized{
        visibility: visible;
      }
      .slick-list{
        overflow: visible;
      }
      .slick-track{
        display: flex;
      }
      .slick-slide{
        display: flex !important;
        height: auto;
        margin: 0 16px;
        transition: opacity .4s;
        opacity: 0;

        > div{
          width: 100%;
          height: 100%;
        }
        &[aria-hidden="false"]{
          opacity: 1;
        }
      }

      .slick-arrow{
        line-height: 40px;
        text-align: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        @media only screen and (max-width: 1179px) {
          top: initial;
          transform: translateY(0);
          bottom: -72px;
        }

        &.slick-prev{
          left: -48px;

          @media only screen and (max-width: 1179px){
            left: calc(50% - 48px);
          }
        }
        &.slick-next{
          right: -48px;

          @media only screen and (max-width: 1179px){
            right: calc(50% - 48px);
          }
        }
      }
    }
  }
}
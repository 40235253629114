.no-padding{
  &__top{
    padding-top: 0 !important;
  }
  &__bottom{
    padding-bottom: 0 !important;
  }
  &__left{
    padding-left: 0 !important;
  }
  &__right{
    padding-right: 0 !important;
  }
}

$spacing: (
  '8': 8px,
  '16': 16px,
  '24': 24px,
  '32': 32px,
);

@each $state, $property-class in $spacing {
  .pb__#{$state}{
    padding-bottom: $property-class;
  }
  .pl__#{$state}{
    padding-left: $property-class;
  }
  .pr__#{$state}{
    padding-right: $property-class;
  }
  .pt__#{$state}{
    padding-top: $property-class;
  }
}

.pb{
  &__16{
    padding-bottom: 16px;
  }
  &__24{
    padding-bottom: 24px;
  }
  &__32{
    padding-bottom: 32px;
  }
}
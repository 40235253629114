.investhub-modal {
  // >.MuiBackdrop-root {
  //   background-color: rgba(#fff, .8);
  // }

  .MuiDialog-paper {
    // box-shadow: var(--default-shadow);
    // padding: 50px;

    // @media screen and (max-width: 1440px) {
    //   padding: 30px;
    // }

    // @media screen and (max-width: 1220px) {
    //   padding: 40px 24px;
    // }
  }

  &__header{
    text-align: center;
    margin-left: 24px;
    margin-right: 24px;

    &-title{
      flex: 1;
      font-size: var(--h2-max-font-size);

      @include max1024{
        font-size: var(--h1-min-font-size);
      }
    }
  }

  &__content{
    // padding: 0 !important;
    // overflow-y: initial;

    .MuiGrid-root{
      // padding-left: 0 !important;
      // padding-right: 0 !important;
    }
  }

  &__footer{
    padding: 0 !important;
    // padding: 28px 0 0 !important;
    // border-top: 1px solid var(--color-border);

    &-inner{
      padding-top: 28px;
      border-top: 1px solid var(--color-border);
      margin-left: 24px;
      margin-right: 24px;
    }

    .btn{
      min-width: 160px;
      text-align: center;
      font-weight: 600;
      outline: none;
      margin-bottom: 0;
    }
  }

  // For tenant only
  &__tenant{
    .MuiPaper-root.MuiDialog-paper{
      @media screen and (max-width: 520px) {
        margin: 24px;
        width: 100%;
      }
    }

    .investhub-modal{
      &__footer{
        &-inner{
          @media screen and (max-width: 480px) {
            flex-flow: column nowrap;
          }

          .btn{
            @media screen and (max-width: 480px) {
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
}
.site-header{
  &__hamburger{
    max-height: 30px;
    .toggle{
      &-icon{
        // https://codepen.io/djuangrm/pen/oNxjggN
        max-width: 32px;
        
        &:before{
          content: '';
          position: absolute;
          transform: translateY(.7em);
          width: 65%;
        }
      }
    }
    .toggle-icon,
    .toggle-icon:before{
      transition: all 250ms ease-in-out;
      border-radius: .6em;
      background-color: var(--color-black);
      height: .22em;
    }
  }
}

#root[aria-hidden="true"]{
  .toggle-icon{
    transform: translateY(0px) rotate(45deg);
    &:before{
      width: 100%;
      transform: translateY(0px) rotate(-90deg);
    }
  }
}

// .site-header__sidebar-navigation{
//   span{
//     display: block;
//     opacity: 0;
//     transition: all 350ms ease-in-out 250ms;
//     transform: translateX(-20px)
//   }
// }
// .MuiDrawer-modal{
//   .site-header__sidebar-navigation{
//     span{
//       opacity: 1;
//       transform: translateX(0);

//       &:nth-child(2){
//         transition: all 350ms ease-in-out 350ms;
//       }
//       &:nth-child(3){
//         transition: all 350ms ease-in-out 450ms;
//       }
//       &:nth-child(4){
//         transition: all 350ms ease-in-out 550ms;
//       }
//       &:nth-child(5){
//         transition: all 350ms ease-in-out 650ms;
//       }
//       &:nth-child(6){
//         transition: all 350ms ease-in-out 650ms;
//       }
//       &:nth-child(7){
//         transition: all 350ms ease-in-out 750ms;
//       }
//     }
//   }
// }
%header__center{
  @include mq_1339{
    padding-left: 40px;
    padding-right: 40px;
  }
}
%header__wrapper{
  @include mq_1179{
    padding-left: 80px;
    padding-right: 80px;
  }
  @include mq_1023{
    padding-left: 40px;
    padding-right: 40px;
  }

  @include mq_639{
    padding-top: 84px;
  }
}

.header{
  &__center{
    @extend %header__center;
  }
  &__logo{
    width: 178px;
  }
  &__nav{
    z-index: 12;
    @include mq_389{
      margin-bottom: 16px;
    }
    .header__link{
      @include mq_389{
        font-size: 21px;
        line-height: 40px;
      }
      &:nth-child(3n),
      &:nth-child(n+3){
        display: block;
      }
    }
  }

  &__search{
    z-index: 12;

    @include mq_639{
      margin-top: 32px;
    }
  }

  &__wrapper{
    @extend %header__wrapper;
  }

  &__button{
    z-index: 12;
  }

  &__burger{
    display: none;
    position: relative;
    width: 32px;
    height: 32px;
    background: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-left: 12px;
    z-index: 12;

    @include mq_1179{
      display: block;
    }

    &:before,
    &:after{
      content: '';
      position: absolute;
      top: 16px;
      left: 6px;
      width: 20px;
      height: 2px;
      background: $color-accent-dark-light;
      border-radius: 2px;
      transition: -webkit-transform .2s;
      transition: transform .2s;
      transition: transform .2s, -webkit-transform .2s;
    }

    &:before{
      -webkit-transform: translateY(-4px);
      transform: translateY(-4px);
    }
    &:after{
      -webkit-transform: translateY(3px);
      transform: translateY(3px);
    }

    &.active{
      &::before{
        -webkit-transform: translateY(0) rotate(-45deg);
        transform: translateY(0) rotate(-45deg);
      }

      &::after{
        -webkit-transform: translateY(0) rotate(45deg);
        transform: translateY(0) rotate(45deg);
      }
    }
  }

  &__list{
    display: flex;
    padding-left: 0;
    list-style: none;
    margin: 0;
  }

  &__item-link{
    .header{
      &__link{
        font-size: 14px;
      }
    }

    &.active{
      .header__link-mega{
        // transform: scale(1) translateX(-50%);
      }
    }
  }

  /* ## Translation 
   */
  &__translation{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 8px;
    padding: 16px 0;
  }
  .translation{
    &__inner{
      height: 34px;
      width: 34px;
      box-shadow: 0 0 0 2px #e6e8ec inset;
      border-radius: 50%;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      position: relative;

      @include dark{
        box-shadow: 0 0 0 2px #353945 inset;
      }
    }
    &__img{
      opacity: 0;
      visibility: hidden;
      transition: opacity .2s ease;
      position: absolute;
      top: 4px;

      &:not(.has-active){
        opacity: 1;
        visibility: visible;
      }
    }
    img{
      border-radius: 50%;
      width: 24px;
      height: 24px;
    }
  }

  .translation-rwd{
    margin-top: 16px;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 0;
    
    .footer__head{
      margin-bottom: 0;
      padding-bottom: 16px;
    }

    &__switch{
      color: #777E90;
      &.active{
        color: #777E90;

        &:hover,
        &:focus,
        &.has-active{
          color: #3772FF;
        }
      }
    }
  }

  // variation 1 - only logo
  &-clear{
    justify-content: center;
    
    .header__logo{
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.header-admin{
  .header{
    &__center{
      @media only screen and (max-width: 1778px) and (min-width: 1340px){
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &__logo{
      @media only screen and (max-width: 1778px) and (min-width: 1180px){
        margin-right: auto;
      }
    }

    &__wrapper{
      @media only screen and (max-width: 1778px) and (min-width: 1180px){
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        flex-direction: column;
        align-items: stretch;
        min-height: calc(var(--vh, 1vh) * 100);
        margin: 0;
        padding: 140px 32px 40px;
        box-shadow: 0px 64px 64px rgba(31,47,70, 0.2);
        background: #FCFCFD;
        visibility: hidden;
        opacity: 0;
        transition: all .2s;
        flex-grow: 0;
        padding-left: 80px;
        padding-right: 80px;
      }

      &.visible{
        @media only screen and (max-width: 1778px) and (min-width: 1180px){
          visibility: visible;
          opacity: 1;
        }
      }
    }

    &__nav{
      @media only screen and (max-width: 1778px) and (min-width: 1180px){
        display: flex;
        flex-direction: column;
        margin: 0 0 40px;
        border: none;
      }

      .header__link{
        @media only screen and (max-width: 1778px) and (min-width: 1180px){
          margin-left: 0;
          font-size: 24px;
          line-height: 64px;
        }
      }
    }

    &__search{
      @media only screen and (max-width: 1778px) and (min-width: 1180px){
        display: block;
        width: 100%;
        margin: auto 0 0;
      }
    }

    &__burger{
      @media only screen and (max-width: 1778px) and (min-width: 1180px){
        display: block;
      }
    }
  }
}

.header-has__megamenu{
  padding-top: 0;
  padding-bottom: 0;
  z-index: initial;

  @include mq_1179{
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 20;
  }
  .header{
    &__line{
      position: relative;
      width: 1px;
      margin-top: 20px; // equals to header-investhub
      margin-bottom: 20px; // equals to header-investhub
      display: block;
      height: 100%;
      padding: 20px 0;
      background-color: $color-accent-gray;
      @include dark(){
        background-color: $color-accent-dark;
      }
      @include mq_1179{
        display: none;
      }
    }

    &__wrapper{
      @include mq_1179{
        top: -20px;
      }
    }

    &__center{
      position: relative;
    }
    &__nav{
      border-color: transparent;

      @include dark(){
        border-color: transparent;
      }
    }
    &__link{
      padding-top: 20px;
      padding-bottom: 20px;
      display: block;
      cursor: pointer;

      @include mq_1179{
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &__panel{
      position: absolute;
      width:calc(100% - 96px);
      top: 100%;
      border-radius: 24px;
      transform: translateZ(-100px);
      left: 48px;

      @include mq_1179{
        display: none;
      }

      &-content{
        position: absolute;
        width: 100%;
        top: 0;
        left: 0px;
        border-radius: 24px;
        overflow: hidden;
        box-shadow: 0px 16px 64px 0 rgba(31, 47, 70, 0.4);
        // margin-top: 16px;

        background-color: $color-accent-white;

        @include dark(){
          background-color: $color-accent-dark-dark;
          box-shadow: 0 16px 64px 0 rgba($color-accent-dark, 0.05);
        }
      }
    }

    &__link-mega{
      position: absolute;
      top: calc(100% - 4px);
      transform: translateZ(1000px);
      left: 0;
      opacity: 0;
      visibility: hidden;
  
      .header{
        &__body{
          opacity: 1;
          position: relative;
          left: 0;
          visibility: visible;
          padding: 48px;
          border-radius: 24px;
          background-color: transparent;
          box-shadow: none !important;
          &:before{
            display: none;
          }
        }
        &__title{
          margin-bottom: 24px;
        }
      }
    }
  }

  // fixed z-index issue

  + .outer__inner{
    z-index: 20;
    position: relative;

    @include mq_1179{
      z-index: initial;
    }
  }
}
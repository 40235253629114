/* Change autocomplete styles in WebKit */
input[type="password"]:-webkit-autofill,
input[type="password"]:-webkit-autofill:focus,
input[type="password"]:-webkit-autofill:hover{
    -webkit-text-fill-color: #fff;
    border: none;
    -webkit-box-shadow: none;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

// #######################################
// Category
// #######################################
.footer__category{
  margin-bottom: 40px;
  font-size: 16px;
  font-family: var(--font-1);
  line-height: 1.5;
  font-weight: 500;

  @media only screen and (max-width: 376px) {
    margin-bottom: 32px;
  }
}

// #######################################
// Text
// #######################################
.footer__text{
  font-family: var(--font-2);
  margin-bottom: 24px;
}

// #######################################
// Subscription
// #######################################
.subscription{
  position: relative;

  > div{
    width: 100%;
  }
}

.subscription__input{
  > div{
    width: 100%;
    &:before,
    &:after{
      display: none;
    }
  }

  input[type="email"]{
    width: 100%;
    height: 48px;
    padding: 0 48px 0 14px;
    border-radius: 24px;
    background: none;
    border: 2px solid var(--color-border-dark);
    font-family: var(--font-1);
    font-weight: 500;
    font-size: 14px;
    line-height: 1.71429;
    color: var(--color-light);
    transition: border-color .2s;

    &:focus{
      border-color: var(--color-border-focus);
    }

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::placeholder {
      color: #777E90;
    }
  }
}

.subscription__button{
  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--color-blue);
  transition: background .2s;

  svg{
    width: 14px;
    height: 14px;
    fill: #FCFCFD;
  }

  &:hover{
    background: var(--color-blue-dark);
  }
}
@mixin dark(){
  body.dark-mode .outer &{
    @content;
  }
}

@mixin darkOutside(){
  body.dark-mode & {
    @content;
  }
}
.country{
  &__checking{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-top: 8px;
    
    .preview__clear{
      &:not(:first-child){
        margin-left: 12px;
      }
    }
  }
}
.country{
  &__inner{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0 -8px;
  }

  &__item{
    padding-bottom: 24px;
    flex: 0 0 calc(33.333% - 16px);
    max-width: calc(33.333% - 16px);
    border-bottom: 1px solid $color-accent-gray;
    margin: 0 8px 32px;
  }
}
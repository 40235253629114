.main__slide{
  &.investhub-main__item{
    @include mq_1023{
      text-align: center;
    }
    .main{
      &__info{
        margin-bottom: 40px;
      }

      &__details{
        order: -1;
        margin-left: 0;
        margin-right: 64px;
        width: 50%;
        margin-right: 0;
        padding-right: 32px;

        @include mq_1023{
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          padding-right: 0;
          padding-bottom: 0;
        }
      }

      &__btns{
        flex-direction: row;

        @include mq_1023{
          justify-content: center;
        }

        @include mq_478{
          flex-direction: column;
        }
      }
      &__button{
        width: initial;

        &:not(:last-child){
          margin-right: 12px;

          @include mq_478{
            margin-right: 0;
            margin-bottom: 12px;
          }
        }
      }

      &__trusted{
        display: flex;
        flex-flow: row wrap;
        margin: 40px -8px 0;

        @include mq_1023{
          justify-content: center;
        }
      }
      &__boxx{
        // flex: 0 0 calc(33.333% - 0px);
        // max-width: calc(33.333% - 0px);
        // overflow: hidden;

        // @include mq_1023{
        //   flex: 0 0 calc(20% - 16px);
        //   max-width: calc(20% - 16px);
        // }

        // @include mq_767{
        //   flex: 0 0 calc(33.333% - 16px);
        //   max-width: calc(33.333% - 16px);
        // }
        
        // @include mq_639{
        //   flex: 0 0 calc(50% - 16px);
        //   max-width: calc(50% - 16px);
        // }
        margin-left: 16px;

        &:not(:last-child){
          margin-right: 16px;
          @include mq_639{
            margin-bottom: 24px;
          }
        }
      }
      &__value{
        margin-right: 0;
        // max-width: 90px;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        // overflow: hidden;

        @include mq_1023{
          max-width: 130px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      &__name{
        margin-top: 0px;
        // max-width: 70px;

        // @include mq_1023{
        //   max-width: initial;
        // }
      }
    }

    .player{
      &__preview,
      &__lottie{
        margin-top: 24px;
        position: relative;
        // box-shadow: 0px 64px 64px -24px rgba(31,47,70, 0.12);

        @include mq_1023{
          margin-top: 0;
        }

        @include dark{
          display: none !important;
        }

        &.dark-mode{
          display: none !important;

          @include dark{
            display: block !important;
          }
        }
      }

      &__lottie{
        margin-left: -6%;
        margin-top: 0;

        @include mq_1023{
          margin-left: 0;
        }
        // lottie item
        > div{
          width: 115% !important;

          @include mq_1023{
            width: 100% !important;
          }
        }
      }

      &__play{
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        cursor: pointer;
        
        height: 64px;
        width: 64px;
        border-radius: 50%;
        background-color: $color-accent-primary;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before{
          content: "";
          position: absolute;
          display: block;
          top: 49%;
          left: 49%;
          transform: translateX(-50%) translateY(-50%);
          background-color: $color-accent-primary;
          border-radius: 50%;
          z-index: 0;
          width: 64px;
          height: 64px;
          animation: pulse-border 1.1s ease-out infinite;
        }

        svg{
          fill: white;
          position: relative;
        }
      }

      &__v2{
        .player__play{
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

@-webkit-keyframes pulse-border{
  0%{
    transform:translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity:1
  }
  100%{
    transform:translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity:0
  }
}
@keyframes pulse-border{
  0%{
    transform:translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity:1
  }
  100%{
    transform:translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity:0
  }
}
.footer__cta{
  position: relative;
  background-color: transparent !important;
  margin-bottom: -180px;

  @media only screen and (max-width: 376px){
    margin-bottom: -210px;
  }

  &:after{
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: var(--color-bg-black);
    z-index: -1;
    height: 200px;
  }

  &_bgwrapper{
    padding: 80px;
    border-radius: 16px;
    background-color: var(--color-blue);

    @media only screen and (max-width: 1023px){
      padding: 40px;
    }
  }

  &_title{
    color: white;
    margin-bottom: 40px;

    @media only screen and (max-width: 1023px){
      margin-bottom: 32px;
      font-size: 42px !important;
    }
    
    @media only screen and (max-width: 767px){
      margin-bottom: 8px;
      font-size: 36px !important;
    }
  }
  &_text{
    color: white;
    font-family: var(--font-2);
    font-size: 16px;
    text-align: center;
    margin-bottom: 24px;

    @media only screen and (max-width: 767px){
      margin-bottom: 32px;
    }
  }

  &_btn{
    text-align: center;

    @media only screen and (max-width: 767px){
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      margin: 0 -16px -16px;
    }


    a{
      &:not(:last-child){
        margin-right: 12px;
      }

      @media only screen and (max-width: 767px) {
        margin: 0 8px 12px !important;
        min-width: 280px;
      }

      @media only screen and (max-width: 376px) {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }
}
.wrapper{
  background-color: transparent;

  :global{
    .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis .recharts-cartesian-axis-ticks > .recharts-cartesian-axis-tick:first-child,
    .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis .recharts-cartesian-axis-ticks > .recharts-cartesian-axis-tick:last-child{
      opacity: 0;
      visibility: hidden;
    }
  }
}
input[type="text"],
input[type="password"]{
  width: 100%;
  padding: 12px 18px;
  font-size: var(--paragraph-max-font-size);
  height: initial;
}

%field_label{
  color: var(--color-grey) !important;
  font-size: var(--paragraph-max-font-size);
  display: block;
  margin-bottom: 4px;
  @media screen and (max-width: 1024px) {
    font-size: var(--paragraph-min-font-size);
  }

  > span{
    color: red;
  }
}

.form-field__label{
  @extend %field_label;
}

.form-field__title{
  color: var(--color-black);
  font-size: var(--paragraph-max-font-size);
  font-weight: 500;
  display: block;
  margin-bottom: 4px;
  @include max1024{
    font-size: var(--paragraph-min-font-size);
  }

  &_v2{
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--color-border);

    .MuiChip-root{
      margin-left: 8px;
      border-radius: .4em;
      padding: .2em .4em;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      background-color: #F2F4F6;
      color: rgba(119, 140, 162, 1);
      box-shadow: none;
    }
  }
}

// Reset label with the animation
%remove_animation{
  &:before,
  &:after{
    display: none;
  }
}
%default_field{
  padding: 16px 22px;
  border: 1px solid var(--color-border);
  border-radius: var(--radius-small);

  @media screen and (max-width: 1024px) {
    padding: 12px 14px;
  }
}

.form-field__label-reset{
  .MuiInputLabel-root{
    @extend %field_label;
    position: relative;
    transform: translate(0);
    margin-bottom: 12px;
    + .MuiInput-formControl{
      margin-top: 0;
      position: relative;
    }
  }

  .MuiInputBase-input.MuiInput-input{
    padding: 0;
  }
  .MuiInputBase-root.MuiInput-root.MuiInput-underline{
    @extend %remove_animation;
  }

  .MuiChip-root,
  .MuiButtonBase-root.MuiChip-root{
    border-radius: .4em;
    padding: .2em .4em;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #F2F4F6;
    color: rgba(119, 140, 162, 1);

    &:hover,
    &:focus{
      background-color: var(--color-blue);
      color: var(--color-white);
    }
  }
}

// Checkbox & Radio
.form-field__label + .form-group__checkbox,
.form-field__label + .form-group__radio{
  margin-top: 8px;
}
// Checkbox
.form-group__checkbox{
  margin-bottom: -16px !important;
  .form-field__checkbox{
    margin-bottom: 16px !important;
    margin-right: 24px !important;
  }
}
.form-field__checkbox{
  // Rework field checkbox to match with design
  text-align: left;
  &.MuiFormControlLabel-root{
    margin-right: 0;
  }
  > .MuiCheckbox-root{
    position: relative;
    margin-left: 12px;
    margin-right: 6px;
    &:before,
    &:after{
      content: "";
      position: absolute;
      transition: all .3s ease;
    }
    &:before{
      left: 0;
      top: 0;
      height: 16px;
      width: 16px;
      border-radius: 3px;
      border: 1px solid var(--color-border);
    }

    &:after{
      height: 9px;
      width: 6px;
      border: 2px solid white;
      border-top: none;
      border-left: none;
      left: 5px;
      top: 2px;
      transform: rotate(45deg);
      opacity: 0;
      visibility: hidden;
    }

    &.Mui-checked{
      &:before{
        border: 1px solid var(--color-blue);
        background: var(--color-blue);
      }
      &:after{
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .MuiSvgIcon-root{
    display: none;
  }

  .MuiFormControlLabel-label{
    color: #808080;
  }
}

.form-group__radio{
  margin-bottom: -16px !important;
  .form-field__radio{
    margin-bottom: 16px !important;
    margin-right: 24px !important;
  }
}
.form-field__radio{
  // SVG
  .MuiSvgIcon-root{
    fill: var(--color-border);
    width: 21px;
    height: 21px;
  }
  // Text
  .MuiTypography-root.MuiFormControlLabel-label{
    color: var(--color-black);
    font-size: var(--paragraph-max-font-size);
    margin-top: 4px;

    @include max1024{
      font-size: var(--paragraph-min-font-size);
    }
  }
  .MuiRadio-root{
    @include max1024{
      position: relative;
      top: 1px;
    }
  }
  // Checked
  .Mui-checked{
    .MuiSvgIcon-root{
      fill: var(--color-blue);
    }
  }
}

.form-field__select{
  &.MuiInputBase-root{
    @extend %remove_animation;

    .MuiSelect-root.MuiSelect-select{
      @extend %default_field;
    }
  }
}

.form-field__number{
  .MuiInputBase-root{
    @extend %remove_animation;

    input[type="number"]{
      @extend %default_field;
    }
  }
}

.form-field__text{
  .MuiInputBase-root{
    @extend %remove_animation;

    input[type="text"]{
      @extend %default_field;
    }
  }

  &-chip{
    .MuiFormControl-root{
      @extend %default_field;
    }

    input[type="text"]{
      border: none !important;
      padding-left: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.form-field__password{
  .MuiInputBase-root{
    @extend %remove_animation;

    input[type="password"]{
      @extend %default_field;
    }
  }
}

.form-field__email{
  .MuiInputBase-root{
    @extend %remove_animation;

    input[type="text"]{
      @extend %default_field;
    }
  }
}

.form-field__datepicker{
  position: relative;
  .MuiInputBase-root{
    @extend %remove_animation;
    @extend %default_field;
    padding-right: 0;
  }
  input[type="text"]{
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
}

.form-field__amount{
  .MuiInputBase-root{
    @extend %remove_animation;
    @extend %default_field;
  }

  input[type="text"]{
    padding: 0 0 0 8px;
  }
  .MuiInputAdornment-root.MuiInputAdornment-positionStart{
    p{
      color: var(--color-black);
      font-size: var(--paragraph-max-font-size);
    }
  }
}

.form-fields__extra{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;

  > .MuiFormGroup-root{
    flex: 0 0 50%;
    max-width: 50%;
  }

  &-login{
    > .MuiFormGroup-root{

      &:last-child{
        text-align: right;

        a{
          line-height: 1.2;
          font-weight: 500;
        }
      }
    }

    &.style_2{
      > .MuiFormGroup-root{
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  &-account{
    > *{
      text-align: center;
      margin: 0 !important;
      line-height: 1.2;
      width: 100%;
    }
    a{
      font-weight: 500;
    }
  }

  &-spacer{
    overflow: hidden;
    text-align: center;
    justify-content: center;
    .or{
      display: inline-block;
      position: relative;
      padding-left: 6px;
      padding-right: 6px;
      &:before,
      &:after{
        content: "";
        position: absolute;
        width: 100vw;
        height: 1px;
        background-color: var(--color-border);
        top: 50%;
        transform: translateY(-50%);
      }
      &:after{
        left: 100%;
      }
      &:before{
        right: 100%;
      }
    }
  }
}

.form-fields__submit{
  .page-main__sign-up & {
    margin-top: 20px;
  }
}

// Form group with icon inside field
.form-group__field-icon{
  position: relative;

  .form-group{
    &__icon{
      border-right: 1px solid var(--color-border);
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }
  }

  .MuiTextField-root{
    flex: 0 0 1;
    width: 100%;

    ::placeholder {
      color: var(--color-text-placeholder);
    }

  }

  input[type="text"],
  input[type="password"]{
    padding-left: 66px;
    border: 1px solid var(--color-border);
    border-radius: var(--radius-small);

    &.focus-visible{
      box-shadow: 0 0 10px rgba(#6FA8FF, .35);
    }
  }
  fieldset{
    display: none;
  }

}

.MuiSelect-root.MuiSelect-select {
  padding-top: 12px;
  padding-bottom: 12px;
}
.admin-settings{
  &__nav{
    margin-bottom: 48px;

    @include mq_1023{
      display: none;
    }

    &-rwd{
      display: none !important;
      margin-bottom: 32px !important;

      @include mq_1023{
        display: inline-flex !important;
      }
    }
  }

  &__col{
    @include mq_767{
      display: block !important;
    }
  }


  &__sorting{
    justify-content: space-between;
    margin-bottom: 16px;

    @include mq_1023{
      display: flex;
    }
    @include mq_767{
      display: block;
    }
  }

  &__newproject{
    @include mq_767{
      width: 100%;
      margin-bottom: 12px;
    }
  }

  &__search{
    @include mq_1023{
      max-width: 280px;
    }
    @include mq_767{
      max-width: 100%;
    }
  }

  &__result{
    line-height: 32px;
    text-align: center;
  }
}

.admin-settings-page{
  &__list{
    justify-content: center;
  }

  &__preview{
    svg{
      width: 110px;
      height: 110px;
    }
  }
}
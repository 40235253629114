.levels{
  &__list{

  }

  &__item{
    display: block;
    margin-top: 0;
    color: $color-accent-dark-second;
    &:not(:first-child){
      padding-top: 12px;
    }
    &:not(:last-child){
      border-bottom: 1px solid $color-accent-gray;
      padding-bottom: 12px;
    }

    span{
      color: $color-accent-dark-light;
    }
  }
}
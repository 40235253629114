.no-border{
  &__top{
    border-top: none !important;
  }
  &__bottom{
    border-bottom: none !important;
  }
  &__right{
    border-right: none !important;
  }
  &__left{
    border-left: none !important;
  }
}
.field__wrapper-phone .phone-container.react-tel-input  .special-label{
    display: none;
}
.field__wrapper-phone .phone-container.react-tel-input .form-control{
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: normal;
    min-width: 0;
    color: white;
    opacity: 0.9;
    text-align: start;
    padding: 1rem;
    background-color: #2b2d33;
    transition-property: color, background-color;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    border-radius: 1rem;
    outline: none;
    height: initial;
    padding-left: 3.5rem;
    font-family: 'Public Sans', sans-serif;
}
.field__wrapper-phone .phone-container.react-tel-input .flag-dropdown.phone-button{
    background: #1e2025;
    border-radius: 8px;
    border: none
}

.field__wrapper-phone .phone-container.react-tel-input .selected-flag{
    padding-left: 16px;
    background-color: transparent;
    width: 48px;
}
.field__wrapper-phone .phone-container.react-tel-input .selected-flag .arrow{
    display: none;
}

.field__wrapper-phone .phone-container.react-tel-input .country-list{
    overflow-x: hidden;
    min-width: 280px;
    top: 100%;
    left: 0;
    margin-top: 0.5rem;
    padding: 1.5rem;
    position: absolute;
    background-color: #1e2025;
    border-radius: 1.5rem;
    border-width: 2px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.1);
    transition-property: all;
    transform: scale(1);
    opacity: 1;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;

    /* Responsiveness for larger screens */
    /* right: 0;  */
    /* Applied in lg (large) screens context */

}



.field__wrapper-phone .phone-container.react-tel-input .country-list::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}
.field__wrapper-phone .phone-container.react-tel-input .country-list::-webkit-scrollbar-track {
    background-color: initial;
}
.field__wrapper-phone .phone-container.react-tel-input .country-list::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #2b2d33;
}
.field__wrapper-phone .phone-container.react-tel-input .country-list::-webkit-scrollbar-thumb:active {
    background-color: #6F737E;
}
.field__wrapper-phone .phone-container.react-tel-input .country-list::-webkit-scrollbar-thumb:hover {
    background-color: #6F737E;
}

.field__wrapper-phone .phone-container.react-tel-input .country-list .country{
    background-color: transparent;
    letter-spacing: -0.005em;
    font-weight: 600;
    line-height: 1.5rem;
    font-size: 0.875rem; /* 14px */
    display: flex;
    height: 3rem; /* 48px */
    justify-content: flex-start;
    align-items: center;
    color: #6f767e;
    transition-property: color;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    padding-left: 0;
    padding-right: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 1rem; /* 16px */
}
.field__wrapper-phone .phone-container.react-tel-input .country-list .country .country-name {
    outline: none;
    background-color: transparent;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.25rem; /* 4px */
    cursor: pointer;
    transition-property: opacity;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    font-family: 'Public Sans', sans-serif;
}

.field__wrapper-phone .phone-container.react-tel-input .country-list .country .country-name:hover {
    opacity: 0.8;
}

.field__wrapper-phone .phone-container.react-tel-input .country-list .country .dial-code {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 82, 255, 0.15);
    margin-left: 0.5rem; /* 8px */
    padding-left: 0.5rem; /* 8px */
    padding-right: 0.5rem; /* 8px */
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 0.375rem; /* 6px */
    color: #0052ff;
    font-size: 0.75rem; /* 12px */
}


.translation_rwd{
  @media only screen and (min-width: 1023px){
    display: none !important;
  }

  &__head{
    color: white;
  }

  &__link{
    color: #777E90 !important;

    &:hover,
    &:focus{
      color: var(--color-blue) !important;
    }
  }
}
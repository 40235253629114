.pricings{

  &__center{

    @include mq_1339{
      padding-left: 40px;
      padding-right: 40px;
    }

    @include mq_1024{
      max-width: 100%;
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  &__title{
    @include mq_478{
      font-size: 24px;
    }
  }

  &__list{
    flex-flow: row wrap;
    &_has_hot{
      // margin-top: 142px;
      margin-bottom: 40px;

      // @include mq_1023{
      //   margin-top: 0;
      // }
    }
  }

  &__item{
    flex: 0 0 calc(25% - 32px);
    max-width: calc(25% - 32px);
    padding: 34px 24px;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    @include mq_1023{
      flex: 0 0 calc(100% - 32px);
      max-width: calc(100% - 32px);
    }

    @include mq_767{
      max-width: 100%;
    }

    .equal-height-JlocK{
      @include mq_1023{
        height: initial !important;
        overflow: unset !important;
      }

      &:not(:first-child){
        margin-top: 32px;
        position: relative;
        border-top: 1px solid #E6E8EC;

        @include dark{
          border-color: #353945;
        }

        .pricings__feature{
          margin-bottom: 16px;
        }
      }
    }
    .pricings__cost{
      + .equal-height-JlocK{
        border: none;
      }
    }

    &:not(:last-child){
      @include mq_1023{
        margin-bottom: 40px;
      }
    }

    &-title{
      @include mq_767{
        font-size: 32px;
        margin-bottom: 16px;
      }
    }

    &-full{
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 40px;
      align-items: flex-start;
      text-align: left;
    }
    &-inner{
      // this should only place inside item-full
      // max-width: 678px;
      margin-left: auto;
      margin-right: auto;
    }
    p{
      font-size: 16px;
      line-height: 1.5;
      color: #353945;

      @include dark{
        color: #E6E8EC;
      }

      a{
        font-weight: 500;
        color: #3772FF;

        &:hover,
        &:focus{
          text-decoration: underline;
        }
      }
    }
    .description__btns{
      margin-top: 32px;
    }
  }

  &__inner{
    flex: 1;
  }

  &__cost{
    justify-content: center;
  }

  &__feature{
    list-style-type: none;
    padding-left: 0;

    @include mq_1023{
      display: flex;
      align-items: center;
      flex-flow: column nowrap;
    }
    @include mq_478{
      display: block;
    }

    & + & {
      margin-top: 40px;
    }

    &-center{
      display: flex;
      align-items: center;
      flex-flow: column nowrap;
    }

    li{
      position: relative;
      padding-left: 36px;
      font-size: 14px;
      font-family: $font_dmsans;
      line-height: 1.14286;
      color: #777E90;
      transition: color .2s;
      text-align: left;

      @include mq_1023{
        text-align: center;
      }
      @include mq_478{
        text-align: left;
      }

      &::before{
        content: "";
        position: absolute;
        top: -2px;
        left: 0;
        width: 24px;
        height: 24px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-2 0a8 8 0 1 1-16 0 8 8 0 0 1 8-8 7.98 7.98 0 0 1 5.942 2.644L11 13.586l-2.793-2.793a1 1 0 0 0-1.414 1.414L9.586 15a2 2 0 0 0 2.828 0l6.687-6.687C19.676 9.416 20 10.67 20 12z' fill='%2383bf6e'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto;
      }

      &.disabled{
        color: $color-accent-gray-dark;
      }

      &:not(:last-child){
        margin-bottom: 24px;
      }

      &.disabled{
        &::before{
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath d='M12 15a1 1 0 0 0 1-1V9a1 1 0 1 0-2 0v5a1 1 0 0 0 1 1zm7.675 2.504L12.87 5.563a1 1 0 0 0-1.738 0L4.327 17.504A1 1 0 0 0 5.196 19h13.61a1 1 0 0 0 .869-1.495zM14.607 4.573c-1.151-2.019-4.062-2.019-5.213 0L2.589 16.514C1.45 18.514 2.894 21 5.196 21h13.61c2.302 0 3.746-2.486 2.606-4.485L14.607 4.573z' fill-rule='evenodd' fill='%23ff6a55'/%3E%3Cpath d='M13 17a1 1 0 1 1-2 0 1 1 0 1 1 2 0z' fill='%23ff6a55'/%3E%3C/svg%3E");
        }
      }
    }
  }

  &__info{
    margin-top: 34px;
    margin-bottom: 16px;
  }

  &__button{
    margin-top: 60px;

    @include mq_478{
      margin-top: 34px;
    }

    // radio group inside
    .MuiFormControlLabel-root{
      margin-right: 0;
    }
    .MuiRadio-root{
      display: none;
    }
    .MuiFormControlLabel-root{
      margin: 0 auto;
    }

    .MuiTypography-root{
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      padding: 0 24px;
      border-radius: 24px;
      font-family: $font_dmsans;
      font-size: 16px;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      transition: all .2s;

      background: none;
      box-shadow: 0 0 0 2px $color-accent-gray inset;
      color: $color-accent-dark-second;
      min-width: 180px;

      @include dark(){
        color: $color-accent-white;
      }

      &:hover,
      &:focus{
        background: $color-accent-primary;
        box-shadow: 0 0 0 2px $color-accent-primary inset;
        color: $color-accent-gray-light;
      }
    }

    .Mui-checked{
      + .MuiTypography-root{
        background-color: $color-accent-dark;
        box-shadow: 0 0 0 2px $color-accent-dark inset;
        color: $color-accent-gray-light;
      }
    }
  }

  &__hot{
    background: $color-accent-dark;
    // transform: scale(1.04);

    // @include mq_1023{
    //   transform: scale(1);
    // }

    @include dark(){
      background-color: $color-accent-white;
    }
    .pricings{
      &__not-included{
        color: $color-accent-white;
        @include dark(){
          color: $color-accent-dark-second;
        }
      }
      &__item{
        &-title{
          color: $color-accent-white;
          @include dark(){
            color: $color-accent-dark-second;
          }
        }
      }

      &__cost{
        .item__counter{
          color: $color-accent-white;
          @include dark(){
            color: $color-accent-dark-second;
          }
        }
      }

      &__info{
        color: $color-accent-white;
        @include dark(){
          color: $color-accent-dark-second;
        }
      }

      &__feature{
        li{
          color: $color-accent-white;
          @include dark(){
            color: $color-accent-dark-second;
          }

          &.disabled{
            color: $color-accent-white-dark;
            @include dark(){
              color: $color-accent-dark-light;
            }
          }
        }
      }

      &__button{
        .MuiTypography-root{
          background-color: $color-accent-primary;
          color: $color-accent-white;
          box-shadow: none;
        }

        .Mui-checked{
          + .MuiTypography-root{
            background-color: $color-accent-gray-light;
            color: $color-accent-dark;

            @include dark(){
              background-color: $color-accent-dark;
              color: $color-accent-gray-light;
            }
          }
        }
      }
    }

    .equal-height-JlocK{
      border-color: rgba(255,255,255, 0.20) !important;

      @include dark(){
        border-color: #E6E8EC !important;
      }
    }
  }
}

.pricing-options{
  &__checkout{
    border-top: 1px solid $color-accent-gray;
    padding-top: 34px;

    @include dark(){
      border-color: #353945;
    }
  }

  &__item{
    align-items: center;

    &:not(:last-child){
      margin-bottom: 16px;
    }
  }

  &__box{
    padding-right: 24px;
    text-align: right;
    .upload__category{
      @include mq_639{
        font-size: 14px;
      }
    }
  }

  &__summary{
    min-width: 240px;

    @include mq_639{
      width: 160px;
      min-width: initial;
    }

    .MuiFormControl-root{
      margin: 0;
    }

    .preview__info{
      margin: 0;
      text-align: right;
    }
  }

  &__button{
    text-align: right;
    margin-top: 16px;
  }
}
.tenant{
  &__row{
    .tenant__col{
      @include mq_767{
        display: block;
      }
    }
  }

  &__col{
  }
  &__button{
    margin-top: 32px;
  }
}
@mixin max1024 {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin max768{
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin max578{
  @media screen and (max-width: 578px) {
    @content;
  }
}

// 520
// 480
@mixin mq_1778{
  @media only screen and (max-width: 1778px){
    @content;
  }
}
@mixin mq_1558{
  @media only screen and (max-width: 1558px){
    @content;
  }
}
@mixin mq_1419{
  @media only screen and (max-width: 1419px){
    @content;
  }
}
@mixin mq_1339{
  @media only screen and (max-width: 1339px){
    @content;
  }
}
@mixin mq_1259{
  @media only screen and (max-width: 1259px){
    @content;
  }
}
@mixin mq_1179{
  @media only screen and (max-width: 1179px){
    @content;
  }
}
@mixin mq_1024{
  @media only screen and (max-width: 1024px){
    @content;
  }
}
@mixin mq_1023{
  @media only screen and (max-width: 1023px){
    @content;
  }
}
@mixin mq_767{
 @media only screen and (max-width: 767px){
   @content;
 } 
}
@mixin mq_639{
  @media only screen and (max-width: 639px){
    @content;
  }
}
@mixin mq_478{
  @media only screen and (max-width: 478px){
    @content;
  }
}
@mixin mq_389{
  @media only screen and (max-width: 389px){
    @content;
  }
}
@mixin mq_320{
  @media only screen and (max-width: 320px){
    @content;
  }
}

@mixin mh_1024{
  @media only screen and (max-height: 1024px){
    @content;
  }
}


@mixin mh_767{
  @media only screen and (max-height: 767px){
    @content;
  }
}

@mixin mh_639{
  @media only screen and (max-height: 639px){
    @content;
  }
}
.text-primary {
  color: #{$primary-color};
}

.text-secondary {
  color: #{$secondary-color};
}

.text-info {
  color: #{$info-color};
}

.text-warning {
  color: #{$warning-color};
}

.text-error {
  color: #{$error-color};
}

.text-success {
  color: #{$success-color};
}

.text-error{
  color: var(--color-red);
  font-style: italic;
  font-size: var(--paragraph-min-font-size);
}
h1, h2, h3, h4, h5, h6{
  @include dark(){
    color: $color-accent-gray-light;
  }
}

.h1{
  @include mq_478{
    font-size: 42px;
  }
}

.h2{
  @include mq_639{
    font-size: 36px;
  }
}
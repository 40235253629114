.customersay{
  &__center{
    max-width: 978px;
    position: relative;
    z-index: 4;
  }

  &__title{
    color: var(--color-yellow);
    margin-bottom: 16px;

    @media only screen and (max-width: 1023px){
      font-size: 42px !important;
    }

    @media only screen and (max-width: 767px){
      margin-bottom: 8px;
      font-size: 36px !important;
    }
  }
  &__text{
    font-family: var(--font-2);
    font-size: 16px;
    line-height: 1.5;
    color: white;
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 64px;
    text-align: center;

    @media only screen and (max-width: 1023px){
      margin-bottom: 32px;
    }
  }

  &__list{
    display: flex;
    flex-flow: row wrap;
    margin: 0 -16px;
  }

  &__item{
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 0 16px;

    @media only screen and (max-width: 767px) {
      flex: 0 0 calc(100% - 32px);
      width: calc(100% - 32px);
    }

    &:not(:last-child){
      @media only screen and (max-width: 767px) {
        margin-bottom: 32px;
      }
    }

    &_inner{
      position: relative;
    }

    &_quote{
      position: relative;
      color: white;
      margin-bottom: 24px;
      font-size: 23px;
      font-weight: normal;

      @media only screen and (max-width: 767px){
        font-size: 18px;
      }
    }

    &_video{
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      padding-bottom: calc(6/10*100%);
      height: 416px;
      width: 100%;

      iframe,
      img{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        object-fit: cover;
      }
    }

    &_link{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(35,38,47,.2);
      visibility: hidden;
      opacity: 0;
      transition: all .3s;
      cursor: pointer;

      svg{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        top: 50%;
        transform: translateY(-43%);
      }
    }


    &:hover,
    &:focus{
      .customersay__item_link{
        opacity: 1;
        visibility: visible;
      }
    }

    &_content{
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      padding-top: 16px;

      > *{
        flex: 1;
      }
    }
    &_name{
      font-size: 18px;
      text-align: left;
      color: white;
    }
    &_title{
      text-align: right;
      color: rgba(white, .7);
      font-family: var(--font-2);
    }
  }
}
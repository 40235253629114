.howtogetstarted{
  padding-top: 0 !important;
  &__title{
    color: var(--color-yellow);
    margin-bottom: 16px;

    @media only screen and (max-width: 1023px){
      font-size: 42px !important;
    }

    @media only screen and (max-width: 767px){
      margin-bottom: 8px;
      font-size: 36px !important;
    }
  }
  &__text{
    font-family: var(--font-2);
    font-size: 16px;
    line-height: 1.5;
    color: white;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 64px;
    text-align: center;

    @media only screen and (max-width: 1023px){
      margin-bottom: 32px;
    }
  }

  &__list{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 0 -16px;

    @media only screen and (max-width: 1023px) {
      margin-bottom: -32px;
    }
  }

  &__card{
    flex: 0 0 calc(25% - 32px);
    width: calc(25% - 32px);
    margin: 0 16px;
    position: relative;

    @media only screen and (max-width: 1023px){
      flex: 0 0 calc(50% - 32px);
      width: calc(50% - 32px);
      margin-bottom: 32px;
    }

    @media only screen and (max-width: 767px){
      flex: 0 0 calc(100% - 32px);
      width: calc(100% - 32px);
    }

    &_inner{
      position: relative;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &_icon{
      width: 70px;
      height: 70px;
      line-height: 70px;
      font-family: var(--font-1);
      font-weight: 700;
      color: #FBBE90;
      text-align: center;
      display: inline-block;
      background-color: white;
      border-radius: 50%;
      font-size: 30px;
      margin-bottom: 32px;
    }

    &_title{
      font-family: var(--font-1);
      font-size: 18px;
      line-height: 1.5;
      font-weight: 600;
      color: white;
      margin-bottom: 16px;
    }
    &_text{
      color: white;
      font-family: var(--font-2);
      font-size: 16px;
    }

    &_line{
      position: absolute;
      left: 0;
      width: 240px;

      @media only screen and (max-width: 1179px){
        display: none;
      }

      svg{
        width: 100%;
      }

      &.line_one{
        left: 61%;
        top: 15%;
      }
      &.line_two{
        top: -1%;
        left: 61%;
      }
      &.line_three{
        left: 61%;
        top: 15%;
      }
    }
  }
}
// Card Onboarding
.card-onboarding {
  position: relative;
  display: block;
  transition: all .3s ease;
  box-shadow: 0 0 50px rgba(111, 168, 255, .15);
  overflow: hidden;
  border-radius: 10px;


  &:hover,
  &:focus {
    box-shadow: 0 0 50px rgba(0, 102, 255, .20);
  }

  &__img {
    img {
      height: 220px;
      width: 100%;
      object-fit: cover;
    }
  }

  &__desc {
    padding: 32px 24px;
    text-align: center;
  }

  &__title {
    margin: 0 0 32px;
    font-weight: 300;
    font-size: var(--h3-min-font-size);
    line-height: 1.2;

    @include max768 {
      font-size: 18px;
    }
  }

  &--disable {
    pointer-events: none;
  }

}

// Card Onboarding
// .card-onboarding{
//     position: relative;
//     display: block;
//     transition: all .3s ease;
//     box-shadow: 0 0 50px rgba(111,168, 255, .15);
//     overflow: hidden;
//     border-radius: 10px;


//     &:hover,
//     &:focus{
//       box-shadow: 0 0 50px rgba(0,102,255, .20);
//     }

//     &__img{
//       img{
//         height: 220px;
//         width: 100%;
//         object-fit: cover;
//       }
//     }

//     &__desc{
//       padding: 32px 24px;
//       text-align: center;
//     }
//     &__title{
//       margin: 0 0 32px;
//       font-weight: 300;
//       font-size: var(--h3-min-font-size);
//       line-height: 1.2;

//       @include max768{
//         font-size: 18px;
//       }
//     }

//   }

.card-onboarding__best {
  position: relative;
  display: block;
  transition: all .3s ease;
  box-shadow: 0 0 50px rgba(111, 168, 255, .15);
  overflow: hidden;
  border-radius: 10px;


  &:hover,
  &:focus {
    box-shadow: 0 0 50px rgba(0, 102, 255, .20);
  }


  &-img {
    position: relative;
    height: 220px;

    img {
      height: 220px;
      width: 100%;
      object-fit: cover;
      position: relative;
    }

    &-title {
      position: absolute;
      display: block;
      z-index: 2;
      left: 24px;
      bottom: 24px;
      margin: 0;
      font-weight: 300;
      font-size: var(--h3-min-font-size);
      line-height: 1.2;
      color: white;

      @include max768 {
        font-size: 18px;
      }
    }
  }

  &-desc {
    padding: 24px;

    p {
      font-size: var(--paragraph-min-font-size);
      color: var(--color-grey);
      margin: 0;
    }

    &-cta {
      margin-top: 16px;
      display: block;
      text-align: center;
    }
  }
}

// .card-onboarding__best{
//     position: relative;
//     display: block;
//     transition: all .3s ease;
//     box-shadow: 0 0 50px rgba(111,168, 255, .15);
//     overflow: hidden;
//     border-radius: 10px;


//     &:hover,
//     &:focus{
//       box-shadow: 0 0 50px rgba(0,102,255, .20);
//     }


//     &-img{
//       position: relative;
//       height: 220px;
//       img{
//         height: 220px;
//         width: 100%;
//         object-fit: cover;
//         position: relative;
//       }
//       &-title{
//         position: absolute;
//         display: block;
//         z-index: 2;
//         left: 24px;
//         bottom: 24px;
//         margin: 0;
//         font-weight: 300;
//         font-size: var(--h3-min-font-size);
//         line-height: 1.2;
//         color: white;

//         @include max768{
//           font-size: 18px;
//         }
//       }
//     }

//     &-desc{
//       padding: 24px;
//       p{
//         font-size: var(--paragraph-min-font-size);
//         color: var(--color-grey);
//         margin: 0;
//       }
//       &-cta{
//         margin-top: 16px;
//         display: block;
//         text-align: center;
//       }
//     }
//   }
.learn{
  &__btns{
    flex-flow: row wrap;

    .learn__button{
      width: initial;

      &:not(:last-child){
        margin-right: 12px;
        margin-bottom: 8px;
      }
    }
  }
  &__head,
  &__inner{
    position: relative;
    max-width: 768px;
  }

  &__content{
    padding-top: 128px;
    background: linear-gradient(178deg, $color-accent-white-dark, rgba(255, 255, 255, 0) 35%);

    @include dark(){
      background: linear-gradient(178deg, $color-accent-dark-second, rgba(255, 255, 255, 0) 35%);
    }
  }

  &__inner{
    margin-left: auto;
    margin-right: auto;
  }

  &__title{
    margin-bottom: 16px;
  }

  &__info{
    max-width: 478px;
    margin: 0 auto 40px;
  }
}

.learn-author{
  padding-bottom: 0;
  border-bottom: 0;
  &.item__user{
    margin-bottom: 48px;
  }

  &__name{
    a{
      color: $color-accent-primary;
      font-weight: 700;

      &:hover,
      &:focus{
        color: $color-accent-primary-dark;
      }
    }
  }
}

.learn-content{
  &__section{
    &.section{
      padding-top: 40px;
      padding-bottom: 40px;
      min-height: initial;

      &:first-child{
        padding-top: 40px;
      }
      &:not(:last-child){
        padding-bottom: 32px;
      }
    }
  }

  &__line{
    margin-top: 80px;
    margin-bottom: 80px;
    border: none;
    border-top: 1px solid $color-accent-gray;

    @include dark(){
      border-color: $color-accent-dark;
    }
  }

  &__column{
    align-items: flex-start;
    .learn-content{
      &__center{
        padding-left: 0;
        padding-right: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: flex-start;
      }
    
      &__wrap{
        flex: 1;
        max-width: initial;
        padding-top: 32px;

        @include mq_767{
          padding-top: 0px;
          margin-bottom: 0;
        }

        @include mq_639{
          width: 100%;
          padding: 24px 24px 0 !important;
          order: 2;
        }
      }

      &__gallery{
        flex: 0 0 360px;
        max-width: 360px;
        width: 360px;
        right: 0;

        @include mq_767{
          flex: 0 0 300px;
          max-width: 300px;
          width: 300px;
        }

        @include mq_639{
          flex: 0 0 100%;
          max-width: 100%;
          width: 100%;
        }
      }

      &__title,
      &__text{
        text-align: left;
        @include mq_639{
          text-align: center;
        }
      }
    }

    &.learn-content{
      &__left{
        .learn-content__wrap{
          padding-right: 48px;
        }
      }
      &__right{
        .learn-content__wrap{
          padding-left: 48px;
          order: 2;
        }
      }
    }
  }

  &__boxrow{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 -16px;

    @include mq_639{
      display: block;
      margin: 0;
    }
  }

  &__boxinner{
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 0 16px;

    @include mq_639{
      width: 100%;
      margin: 0;
    }

    &:not(:last-child){
      margin-bottom: 32px;
    }

    .learn-content{
      &__gallery{
        width: 100%;
      }

      &__wrap{
        padding: 24px;
      }

      &__title{
        margin-bottom: 16px;
      }
    }
  }

  &__gallery{
    position: relative;
    top: 0;
    transform: translateY(0);
    right: 0;
  }
  &__preview{
    overflow: hidden;
    border-radius: 12px;
    padding-bottom: calc(5/7*100%);
    img{
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__title{
    margin-bottom: 24px;

    @include mq_1023{
      display: block;
    }
  }

  &__text{
    p{
      &:not(:last-child){
        margin-bottom: 24px;
      }
    }
  }
}
.investhub-stepper {
  padding: 8px 0 60px !important;

  @include max1024 {
    padding-bottom: 28px !important;
  }
  @include max768{
    display: none !important;
  }

  // Step Number
  .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel{
    position: relative;
    top: -5px;
  }
  .MuiSvgIcon-root.MuiStepIcon-root {
    width: 32px;
    height: 32px;
    color: white;
    box-shadow: var(--shadow-black-light);
    border-radius: 50%;
    z-index: 2;
    .MuiStepIcon-text {
      fill: var(--color-blue);
    }

    &.MuiStepIcon-active,
    &.MuiStepIcon-completed{
      color: var(--color-blue);
      .MuiStepIcon-text {
        fill: white;
      }
    }
  }

  // Step Line
  .WithStyles\(ForwardRef\(StepConnector\)\)-line-10{
    height: 1px;
    background-color: var(--color-border);
  }

  // Step Text
  .MuiTypography-root.MuiStepLabel-label{
    font-size: var(--paragraph-min-font-size);
    color: var(--color-black);
    margin-top: 6px;

    @include max1024{
      display: none;
    }
  }
}
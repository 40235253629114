.usecases{
  &__center{
    max-width: 1280px;
  }

  &__top{
    max-width: 100%;
  }
  &__title{
    color: $color-accent-primary !important;

    &:hover,
    &:focus{
      color: $color-accent-primary-dark !important;
    }

    .usecases__unlink &{
      color: $color-accent-dark-second !important;

      @include dark(){
        color: $color-accent-gray-light !important;
      }
    }
  }
  &__item{
    width: 100%;
    border-bottom: 1px solid $color-accent-gray;
    border-radius: 0;
    background-color: transparent !important;
    padding: 0 0 32px;
    margin: 0 16px 32px;
    
    @include mq_767{
      margin-left: 0;
      margin-right: 0;
    }

    @include dark(){
      border-color: $color-accent-dark;
    }
    &:before,
    &:after{
      display: none;
    }

    &:not(:first-child){
      padding-top: 16px;
    }

    &.usecases__unlink{
      cursor: initial;
    }
  }

}
.pagewrap {
  .section {
    padding: 128px 0;
    background-color: var(--color-dark);
  }

  @media only screen and (max-width: 1339px) {
    .section {
      padding: 112px 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .section {
      padding: 64px 0;
    }
  }
}
.managemonitorregister{
  &__center{
    text-align: center;
    position: relative;
    z-index: 4;
  }
  &__title{
    color: var(--color-yellow);
    margin-bottom: 16px;

    @media only screen and (max-width: 1023px){
      font-size: 42px !important;
    }

    @media only screen and (max-width: 767px){
      margin-bottom: 8px;
      font-size: 36px !important;
    }
  }
  &__text{
    font-family: var(--font-2);
    font-size: 16px;
    line-height: 1.5;
    color: white;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
    text-align: center;

    @media only screen and (max-width: 1023px){
      margin-bottom: 24px;
    }
  }
}

.table{
  border-radius: 16px;
  background-color: white;
  box-shadow: 10px 10px 0px 0px rgba(255, 255, 255, .21);

  display: table;
  width: 100%;

  @media only screen and (max-width: 1023px){
    display: block;
  }

  &__row{
    display: table-row;

    &:first-child{
      .table__col{
        padding-top: 8px;
        padding-bottom: 16px;
        font-family: var(--font-1);
        font-size: 12px;
        font-weight: 700;
        line-height: 1.33333;
        font-weight: 500;
        color: #6F767E;
      }

      @media only screen and (max-width: 1023px){
        display: none;
      }
    }
    &:nth-child(2n){

      .table__col{
        background-color: rgba(239, 239, 239, 0.35);

        @media only screen and (max-width: 1023px){
          background-color: transparent;
        }
      }

      @media only screen and (max-width: 1023px){
        background-color: rgba(239, 239, 239, 0.35);
      }
    }

    &:not(:first-child){

      .table__col{
        &:first-child{
          color: #6F767E;
        }
      }
    }

    @media only screen and (max-width: 1023px){
      display: flex;
      flex-direction: column;
      padding: 16px 24px;
    }
  }

  &__col{
    display: table-cell;
    padding: 10px 16px;
    color: #1A1D1F;
    font-family: var(--font-2);
    font-size: 14px;
    font-weight: 600;

    &:first-child{
      padding-left: 24px;

      @media only screen and (max-width: 1023px) {
        padding-left: 0;
        padding-bottom: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid #EFEFEF;
      }
    }
    &:last-child{
      padding-right: 24px;

      @media only screen and (max-width: 1023px) {
        padding-right: 0;
      }
    }

    @media only screen and (max-width: 1023px) {
      display: flex;
      padding: 0;
    }

    &:not(:first-child){
      @media only screen and (max-width: 1023px) {
        margin-bottom: 12px;
      }
    }

    &_date{
      min-width: 140px;
    }
  }

  &__label{
    display: none;
    font-family: var(--font-1);
    margin-right: auto;
    font-weight: 500;
    line-height: 1.71429;

    @media only screen and (max-width: 1023px) {
      display: block;
    }
  }

  &__status{
    display: inline-block;
    white-space: nowrap;
    padding: 0 8px;
    border-radius: 6px;
    color: #1A1D1F;
    font-weight: 600;
  }
}

.status{
  &__green{
    background-color: #B5E4CA;
  }
  &__yellow{
    background-color: #FFD88D;
  }
  &__white{
    background-color: white;
  }
}

.shorten{
  top: 7px;
}
.details{
  &__fieldset{
    .field{
      width: 100%;
  
      &__group{
        display: flex;
        justify-content: flex-start;
        flex-flow: row wrap;
        margin: 0 -8px;
  
        @include mq_639{
          display: block;
          text-align: left;
        }

        + .field__group{
          @include mq_639{
            margin-top: 32px;
          }
        }

        .field{
          flex: 0 0 calc(50% - 16px);
          width: calc(50% - 16px);
          margin-left: 8px;
          margin-right: 8px;
  
          @include mq_639{
            width: calc(100% - 16px) !important;
          }

          &:not(:last-child){
            @include mq_639{
              margin-bottom: 32px;
            }
          }

  
          &__full{
            flex: 0 0 calc(100% - 16px);
            width: calc(100% - 16px);
          }
        }

        &-3{
          .field{
            flex: 0 0 calc(33.333% - 16px);
            width: calc(33.333% - 16px);
          }
        }
      }

      &__group{
        &-inline{
          display: block !important;
          .field{
            width: initial !important;
            display: inline-block;
          }
        }
      }
    }
  }

  &__foot{
    .details__btns{
      width: 100%;
      &_clear{
        margin-top: 0;
        border-top: none;
        padding-top: 0;
      }

      &_flex{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
      }

      button{
        margin-right: 12px;
        
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }

}

.field{
  &__label{
    line-height: 1.4;
  }

  // Error
  .Mui-error,
  .MuiBox-root .text-error{
    color: $color-accent-red;
    font-family: $font_dmsans;
    font-weight: 700;
  }

  // Helper
  .MuiFormHelperText-root{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #777E90;
  }
}

/*  
 * Field has icon
 **/
.field-has__icon{
  // reset
  .MuiInput-underline:before,
  .MuiInput-underline:after{
    @extend %fieldResetHide;
  }

  .MuiInputBase-input{
    @extend %fieldInputWrapperHasIcon;
  }

  // layout
  .MuiTextField-root{
    @extend %fieldResetSpace;
  }

  .MuiInputBase-root{
    @extend %fieldInputStyling;
  }

  // icon
  .MuiInputAdornment-root{
    display: flex;
    justify-content: center;
    @extend %fieldIconWrapper;
  }

  .MuiSvgIcon-root{
    @extend %fieldIconSvg;
  }
}

/*  
 * Field has button
 **/
.field-has__button{
  .MuiInputBase-root {
    @include mq_639{
      display: block;
    }

    input{
      padding-right: 188px !important;

      @include mq_639{
        padding-right: 14px !important;
        box-sizing: border-box;
      }
    }
  }

  .MuiInputAdornment-root{
    position: absolute;
    right: 6px;
    top: 24px;

    @include mq_639{
      position: relative;
      height: initial;
      right: initial;
      top: 0;
      margin-top: 12px;
      margin-left: 0;
      display: block;
      width: 100%;
    }
  }

  .button{
    @include mq_639{
      width: 100%;
    }
  }
}

/*  
 * Field has text
 **/
.field-has__text{
  .MuiInputBase-root {
    @include mq_639{
      display: block;
    }

    input{
      padding-right: 188px !important;
      color: #23262F;

      @include darkOutside{
        color: #FCFCFD;
      }
      @include mq_639{
        padding-right: 14px !important;
        box-sizing: border-box;
      }
    }
  }

  .MuiInputAdornment-root{
    position: absolute;
    right: 6px;
    top: 24px;
    @extend %fieldAdornmentTypography;
    color:  #777E90;

    @include mq_639{
      position: relative;
      height: initial;
      right: initial;
      top: 0;
      margin-top: 12px;
      margin-left: 0;
      display: block;
      width: 100%;
    }
  }

  span{
    @include mq_639{
      width: 100%;
    }
  } 
}

/*  
 * Field has url
 **/
.field-has__url{

  .MuiTypography-root{
    @extend %fieldAdornmentTypography;
  }
}

.field-has__symbol{
  // Input
  &.MuiTextField-root{
    .MuiInputBase-adornedStart{
      input{
        padding-left: 42px;
      }
    }
  }

  // Adornment
  .MuiInputAdornment-root{
    position: absolute;
    &.MuiInputAdornment-positionStart{
      left: 14px;
    }
  }
  .MuiInputAdornment-root .MuiTypography-root{
    @extend %fieldAdornmentTypography;
  }
}
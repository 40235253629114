.topusecases{
  padding-bottom: 32px !important;
  &__title{
    color: var(--color-yellow);
    margin-bottom: 64px;

    @media only screen and (max-width: 1023px){
      font-size: 42px !important;
    }

    @media only screen and (max-width: 767px){
      margin-bottom: 48px;
      font-size: 36px !important;
    }
  }

  &__list{
    column-gap: 32px;
    column-count: 3;
    margin-bottom: -32px;

    @media only screen and (max-width: 976px) {
      column-count: 2;
    }
    @media only screen and (max-width: 576px) {
      column-count: 1;
    }
  }

  &__item{
    width: 100%;
    display: inline-block;
    margin-bottom: 32px;
    &_inner{
      border-radius: 16px;
      background-color: white;
      padding: 32px;
      box-shadow: 10px 10px 0px 0px rgba(255, 255, 255, .21);

      @media only screen and (max-width: 767px) {
        border-radius: 12px;
        padding: 24px;
      }
    }

    &_header{
      display: flex;
      gap: 24px;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 24px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 16px;
        gap: 16px;
      }
    }

    &_icon{
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      height: 63px;
      width: 63px;
      box-shadow: 0 0 0 1px #DDE1E0;
      border-radius: 50%;
    }
    &_title{
      flex: 1;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 600;
    }
    &_description{
      font-family: var(--font-2);
      font-size: 16px;
    }
  }
  
}
.signinup{
  &__form{
    justify-content: center;
  }

  &__inner{
    border: none;
    padding: 0;

    &:hover,
    &:focus{
      box-shadow: none;
    }
  }

  &__note{
    a{
      color: $color-accent-dark-light;
      transition: color .2s;
      font-weight: 700;
      &:hover,
      &:focus{
        color: $color-accent-primary;
      }
    }
  }

  &__btns{
    justify-content: center;
  }

  &__button{
    min-width: 180px;
  }

  .text-right{
    @include mq_639{
      text-align: left;
    }
  }
}
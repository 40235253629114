.profile{
  // &__body{
  //   padding-top: 0;
  // }
  &__head{
    // padding-bottom: 64px;
    background-color: $color-accent-white-dark;

    @include dark(){
      background-color: $color-accent-dark-second;
    }
  }

  // .user{
    // width: 100%;
    // margin: -32px 0 48px;
  // }
}
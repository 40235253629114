.wrapper {
  @apply relative before:top-0 before:left-0 before:absolute before:content-['']
    before:border-[2px] bg-black-light before:bg-[100%] p-4
    before:border-solid rounded-2xl before:rounded-[inherit]
    before:w-full before:h-full font-medium text-[#9b9b9b]
    text-sm leading-5 before:transition-all before:duration-[0.2s]
    before:ease-linear before:pointer-events-none before:outline-none
    before:border-transparent;
}

.wrapper.focused {
  @apply before:border-white/20;
}

.label {
  @apply mb-2 text-[13px] text-gray-lighter;
}

.field :global(.phone-container.react-tel-input) :global(.special-label) {
  @apply hidden;
}

.field :global(.phone-container.react-tel-input) :global(.form-control) {
  @apply w-full text-base leading-6 font-normal text-white opacity-90
    p-4 bg-[#2b2d33] transition-colors duration-200 ease-linear
    focus:bg-gray-700 rounded-xl outline-none min-w-0 h-auto pl-14
    font-['Public_Sans'] border-0;
}

.field :global(.phone-container.react-tel-input) :global(.flag-dropdown.phone-button) {
  @apply bg-transparent border-none;
}

.field :global(.phone-container.react-tel-input) :global(.selected-flag) {
  @apply pl-4 bg-transparent w-12;
}

.field :global(.phone-container.react-tel-input) :global(.selected-flag .arrow) {
  @apply hidden;
}

.field :global(.phone-container.react-tel-input) :global(.country-list) {
  @apply overflow-x-hidden min-w-[280px] top-full left-0 mt-2 p-6
    absolute bg-black-dark rounded-3xl border-2 border-solid border-white/10
    transition-all scale-100 opacity-100 duration-200 ease-out;
}

.field :global(.phone-container.react-tel-input) :global(.country-list::-webkit-scrollbar) {
  @apply h-2 w-2;
}

.field :global(.phone-container.react-tel-input) :global(.country-list::-webkit-scrollbar-track) {
  @apply bg-transparent;
}

.field :global(.phone-container.react-tel-input) :global(.country-list::-webkit-scrollbar-thumb) {
  @apply rounded-md bg-[#2b2d33];
}

.field :global(.phone-container.react-tel-input) :global(.country-list::-webkit-scrollbar-thumb:active),
.field :global(.phone-container.react-tel-input) :global(.country-list::-webkit-scrollbar-thumb:hover) {
  @apply bg-[#6F737E];
}

.field :global(.phone-container.react-tel-input) :global(.country-list .country) {
  @apply bg-transparent tracking-tight font-semibold leading-6 text-sm
    flex h-12 justify-start items-center text-[#6f767e]
    transition-colors duration-200 ease-linear px-0 py-4 rounded-2xl;
}

.field :global(.phone-container.react-tel-input) :global(.country-list .country .country-name) {
  @apply outline-none bg-transparent text-white inline-flex items-center
    justify-end gap-1 cursor-pointer transition-opacity duration-200
    ease-linear font-['Public_Sans'];
}

.field :global(.phone-container.react-tel-input) :global(.country-list .country .country-name:hover) {
  @apply opacity-80;
}

.field :global(.phone-container.react-tel-input) :global(.country-list .country .dial-code) {
  @apply inline-flex justify-center items-center bg-[rgba(0,82,255,0.15)]
    ml-2 px-2 py-[2px] rounded-md text-[#0052ff] text-xs;
}

.error {
  @apply text-[13px] text-red-200;
}

.hint {
  @apply mt-4 text-[13px] text-center text-gray-lighter;
}

.spacer {
  @apply w-full h-4;
}

.button {
  @apply w-full inline-flex justify-center items-center px-6 rounded-2xl h-14
    font-medium text-white transition-all duration-200;
}

.button:disabled {
  @apply bg-gray-700 cursor-not-allowed;
}

.button:not(:disabled) {
  @apply bg-primary hover:bg-primary-hover;
}
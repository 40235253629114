.site-main__dashboard {
  // padding-top: 40px;
  // padding-bottom: 45px;
  // ^^ Use this properties instead of 85px top and bottom padding

  .dashboard-tenant{
    margin-top: 40px;
    margin-bottom: 40px;
    @include max1024{
      margin-top: 28px;
    }
  }
}


@import './navigation';
@import './contents';
// Card Wallet Connect
.card-wallet__connect{
    padding: 24px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 50px rgba(111,168, 255, .15);
    display: block;
    transition: all .3s ease;
  
    &:hover,
    &:focus{
      box-shadow: 0 0 50px rgba(0,102,255, .20);
    }
  
    &-img{
      min-height: 65px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 16px;
  
      img{
        display: inline-block;
        height: 60px;
        width: auto;
        object-fit: contain;
      }
    }
  
    &-title{
      font-weight: 300;
      font-size: var(--h3-min-font-size);
      line-height: 1.2;
      margin-bottom: 16px;
  
      @include max768{
        font-size: 18px;
      }
    }
  
    &-desc{
      font-size: var(--paragraph-min-font-size);
      color: var(--color-grey);
      p{
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
.child-cell:first-child {
    padding-left: 2.25rem;
}

/* Z-index first col */
.sticky-first-cell > th:first-child{
    z-index: 50;
}
.sticky-first-cell > td:first-child{
    z-index: 9;
}

/* active sticky */
.sticky__active{
    box-shadow: 1px 6px 6px 0px rgba(0,0,0,0.2);

}

/* Scroll active */
.scroll__active{
    position: relative;
    display: inline-flex;
}

/* Scrollbar */
.scroll-bar{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 8px;
    background-color: #6F737E;
    border-radius: 6px;
    --tw-bg-opacity: 1;
    border-radius: .5rem;
    outline: none;
    touch-action: none;
}
.scroll-bar::-webkit-slider-thumb{
    background-color: #2b2d33;
    -webkit-appearance: none;
    appearance: none;
    height: 8px;
    border-radius: .5rem;
    cursor: pointer;
    -webkit-transition-property: background-color;
    transition-property: background-color;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .2s;
    width: 30%;
}

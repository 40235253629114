// This is default block
.investhub__field{
  // if combine with material ui
  &.MuiTextField-root{
    height: initial;
    .MuiInput-underline{
      &:before,
      &:after{
        @extend %fieldResetHide;
      }
    }

    input{
      border: 2px solid $color-accent-gray;  
      font-family: $font_poppins;
    
      @include dark{
        color: $color-accent-gray-light;
        border-color: $color-accent-dark;
      }
    }

    .Mui-focused{
      input{
        border-color: $color-accent-dark-light;

        @include dark{
          border-color: $color-accent-dark-light;
        }
      }
    }
  }
}

// Overwrite header input
.header__input{
  // if combine with material ui
  &.MuiTextField-root{
    input{
      padding: 0 42px 0 16px !important;
      height: 36px;
      font-size: 12px;
      line-height: 1.66667;
      transition: border .2s;
      border-radius: 8px;
    }
  }
}

// Overwrite subscription field
// Overwrite explore search field
.subscription__input,
.catalog__input{
  // if combine with material ui
  &.MuiTextField-root{
    input{
      height: 44px;
      padding: 0 48px 0 14px;
      font-size: 14px;
      border-radius: 24px;
      line-height: 1.71429;
      transition: border .2s;
    }
  }
}

// Overwrite with field - mean with all wrapper with samelevel using .field
.field{
  &.MuiTextField-root{
    margin-top: 0;

    // label
    .MuiFormLabel-root{
      margin-bottom: 12px;
      font-size: 12px;
      line-height: 1.4;
      font-weight: 700;
      text-transform: uppercase;
      color: $color-accent-gray-dark;

      transform: scale(1);
      position: relative;
      text-align: left;

      + .MuiInput-formControl{
        margin-top: 0;
      }
    }

    input{
      border-radius: 12px;
      font-size: 14px;
      height: 44px;
      padding: 0 14px;
      line-height: 1.71429;
      font-weight: 500;
      color: $color-accent-dark-second;
      transition: border-color .2s;
    }
  }
}
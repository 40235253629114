.field__datewrapper{
  :global{
    .react-datepicker-popper{
      z-index: 22;
    }
    .react-datepicker{
      font-family: 'Public Sans', sans-serif;
      background-color: #1e2025;
      border-color: rgba(255,255,255, 0.1);
      border-radius: 1rem;

      // Header
      &__header{
        border-top-right-radius: 1rem;
        background-color: rgba(#2b2d33, .9);
        border-bottom-color: rgba(255,255,255, 0.1);
        padding-top: 16px;
        padding-bottom: 16px;
      }
      &__navigation{
        top: 12px;
      }
      &__current-month{
        font-family: 'Public Sans', sans-serif;
        color: white;
        font-size: 18px;
      }
      &__year{
        &-dropdown-container{
          margin-top: 8px;
          color: white;
          font-weight: bold;
        }
        &-read-view{
          border: none;
        }
      }
      &__day{
        color: white;
        &-names{
          font-family: 'Public Sans', sans-serif;
          color: white;
          margin-top: 8px;
        }
        &-name{
          color: white;
          font-family: 'Public Sans', sans-serif;
        }

        &--disabled {
          color: #777e90;
        }
        &--keyboard-selected,
        &--selected{
          background-color: #0052ff !important;
        }
        &:not([aria-disabled=true]):hover{
          color: white;
          background-color: #fff3;
        }
      }

      &__triangle{
        fill: rgba(255,255,255, 0.1) !important;
        color: rgba(255,255,255, 0.1) !important;
        stroke: rgba(255,255,255, 0.1) !important;
      }

      &__year-dropdown{
        color: white;
        border-color: #fff3;
        background-color: #1e2025;
        &--scrollable{
          &::-webkit-scrollbar{
            width: 8px;
            height: 8px;

            &-track{
              background-color: initial;
            }

            &-thumb{
              border-radius: 6px;
              background-color: #2b2d33;

              &:hover,
              &:active{
                background-color: #6F737E;
              }
            }
          }
        }
      } 

      &__year-option{
        &:hover{
          background-color: #0052ff !important;
        }
      }
  

      // Icons
      .react-datepicker__navigation-icon::before, 
      .react-datepicker__year-read-view--down-arrow, 
      .react-datepicker__month-read-view--down-arrow, 
      .react-datepicker__month-year-read-view--down-arrow{
        border-width: 2px 2px 0 0;
        border-color: white;
      }
    }
  }
}
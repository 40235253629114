.description{
  &.investhub-description{
    .description{
      &__center{
        @include mq_1339{
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
        }

        @include mq_767{
          flex-flow: column nowrap;
        }
      }

      &__wrap{
        @include mq_1339{
          padding-top: 24px;
        }
        @include mq_767{
          margin-bottom: 0;
        }
      }
      &__gallery{
        right: calc(50% - 560px);
        width: 560px;

        @include mq_1339{
          right: initial;
          flex: 1;
          position: relative;
          top: 0;
          width: initial;
          transform: translateY(0);

          padding-left: 32px;
        }

        @include mq_767{
          width: 100%;
          padding-left: 0;
          padding-bottom: 32px;
          order: -2;
        }

        @include mq_478{
          margin: 0;
        }

        img{
          border-radius: 8px;
        }
      }
    }

    // if image-gallery on left
    &__left{
      .description{
        &__gallery{
          right: initial;
          left: calc(50% - 560px);

          @include mq_1339{
            left: initial;
            padding-left: 0;
            padding-right: 32px;
          }

          @include mq_767{
            padding-right: 0;
            padding-bottom: 32px;
          }
        }

        &__wrap{
          margin-left: auto;
          text-align: right;

          @include mq_1339{
            order: 2;
          }
          @include mq_767{
            text-align: center;
          }
        }
      }
    }
  }
}
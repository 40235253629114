.investhub-date{
  // reset
  .MuiInput-underline:before,
  .MuiInput-underline:after{
    @extend %fieldResetHide;
  }

  .MuiInputBase-input{
    @extend %fieldInputWrapperHasIcon;
  }

  // layout
  .MuiTextField-root{
    width: 100%;
  }

  .MuiInput-root{
    @extend %fieldInputStyling;
  }

  // icon
  .MuiIconButton-root{
    @extend %fieldIconWrapper;
  }
  .MuiSvgIcon-root{
    @extend %fieldIconSvg;
  }
}
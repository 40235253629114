.wrapper {

}

// .riskScore {
//   position: relative;
//   width: 120px;
//   height: 120px;

//   .circleProgress {
//     transform: rotate(-90deg);
//     path {
//       transition: stroke-dasharray 0.5s ease;
//     }
//   }

//   .scoreContent {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     text-align: center;
//   }

//   .scoreValue {
//     font-size: 24px;
//     font-weight: 600;
//     color: var(--white);
//   }

//   .scoreLabel {
//     font-size: 12px;
//     color: var(--gray-lighter);
//   }
// }

// .riskBadge {
//   background: rgba(0, 82, 255, 0.1);
//   color: var(--primary);
//   padding: 6px 12px;
//   border-radius: 20px;
//   font-size: 14px;
//   font-weight: 500;
// }

// .distributionItem {
//   .progressBar {
//     background: var(--black-dark);
//     height: 8px;
//     border-radius: 4px;
//     overflow: hidden;
//   }

//   .progressFill {
//     background: var(--primary);
//     height: 100%;
//     border-radius: 4px;
//     transition: width 0.5s ease;
//   }
// }

.recommendationCard {
  padding: 16px;
  border-radius: 12px;
  font-size: 14px;
  letter-spacing: -0.02em;
  font-family: Public Sans, sans-serif;
  
  &.success {
    background: rgba(16, 185, 129, 0.1);
    color: #10B981;
  }
  
  &.info {
    background: rgba(0, 82, 255, 0.1);
    color: var(--primary);
  }
}

// @media (max-width: 1023px) {
//   .wrapper {
//     padding: 16px;
//   }
// }
.site-footer {
  border-top: 1px solid var(--color-grey-thirdy);

  &__top {
    padding-top: var(--distance-max-normal);
    padding-bottom: var(--distance-min-normal);

    @include max1024{
      padding-top: 84px;
      padding-bottom: 42px;
    }

    .site-footer__widgets {
      @include max1024{
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
      }
      aside {
        flex: 0 0 25%;
        max-width: 25%;

        @include max1024{
          flex: 0 0 50%;
          max-width: 50%;
          padding-left: 20px;
          padding-right: 20px;
        }

        @include max768{
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }

    .site-footer__widget{
      + .site-footer__widget{
        @include max1024{
          margin-top: 24px;
        }
      }
    }
  }

  // &__bottom {
  //   &-inner {
  //     padding-top: var(--distance-min-xsmall);
  //     padding-bottom: var(--distance-min-xsmall);
  //     border-top: 1px solid var(--color-grey-thirdy);

  //     @include max768{
  //       flex-flow: column nowrap;
  //       justify-content: center;
  //     }
  //   }

  //   .site-footer {
  //     &__credit {
  //       margin-right: 60px;
  //       font-size: var(--paragraph-min-font-size);

  //       @include max768{
  //         margin-right: 0;
  //       }
  //     }

  //     &__link {
  //       flex: 1;

  //       ul {
  //         @extend %reset_list;
  //         text-align: left;
  //       }

  //       li {
  //         display: inline-block;
  //         margin-right: 30px;

  //         @include max1024{
  //           margin-right: 16px;
  //         }

  //         &:last-child{
  //           @include max1024{
  //             margin-right: 0;
  //           }
  //         }
  //       }

  //       a {
  //         color: var(--color-grey);
  //         font-size: var(--paragraph-min-font-size);
  //         display: block;

  //         &:hover,
  //         &:focus {
  //           color: var(--color-black-secondary);
  //         }
  //       }
  //     }

  //     &__info {
  //       font-size: var(--paragraph-min-font-size);

  //       @include max768{
  //         order: -1;
  //         margin-bottom: 12px;
  //       }
  //       img {
  //         position: relative;
  //         top: 3px;
  //         margin-left: 5px;
  //         height: 14px;
  //         width: 14px;
  //       }
  //     }
  //   }
  // }

  &__widget {
    font-size: var(--paragraph-min-font-size);
    line-height: 1.85;

    &-title {
      margin-bottom: 28px;

      @include max1024{
        margin-bottom: 16px;
      }
    }

    // &-company {
    //   .site-footer__widget-inner {
    //     max-width: 330px;
    //   }

    //   &-image {
    //     display: inline-block;
    //   }

    //   .site-socmed {
    //     margin-top: 40px;
    //   }
    // }

    // &-link {
    //   padding-left: 60px;
    //   padding-right: 60px;

    //   span{
    //     display: block;
    //     + span{
    //       margin-top: 4px;
    //     }
    //   }
    //   a {
    //     color: var(--color-grey);
    //     font-size: var(--paragraph-min-font-size);
    //     display: inline-block;

    //     &:hover,
    //     &:focus {
    //       color: var(--color-black-secondary);
    //     }
    //   }
    // }

    &-subscribe{
      .site-footer__subscribe{
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        box-shadow: 0 5px 50px rgba(#7B7979, .1);
        margin-top: 20px;

        .MuiFormControl-root{
          flex: 1;
        }
      }
      .MuiInputBase-formControl{
        &:before,
        &:after{
          display: none;
        }
      }
      
      input[type="text"],
      input[type="email"],
      .btn{
        padding: 12px 16px;
      }

      input[type="text"],
      input[type="email"]{
        padding-right: 4px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      .btn{
        text-transform: capitalize;
        border-radius: 33px 10px 10px 33px;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 0;
        font-weight: 600;
      }
    }
  }
}
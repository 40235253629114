%label_table{
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-accent-gray-dark;  
}

%text_table{
  font-family: $font_dmsans;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
}

.investhub-table{
  &__wrapper{
    // margin: 0 -24px;
  }

  &__box{
    display: table;
    width: 100%;
    overflow: hidden;
    position: relative;
    @include mq_1023{
      display: block;
    }

    &-styled{
      border-radius: 12px;
      border: 2px solid $color-accent-gray;

      @include dark(){
        border-color: $color-accent-dark;
      }

      .investhub-table{
        &__row{
          &:first-child{
            .investhub-table__col{
              padding-top: 16px;
              padding-bottom: 16px;
            }
          }
        }
      }
    }
  }

  &__row{
    display: table-row;

    @include mq_1023{
      display: flex;
      flex-direction: column;
      padding: 16px 24px;
    }

    // first-child
    &:first-child{
      @extend %label_table;

      @include mq_1023{
        display: none;
      }
    }
    &-empty{
      display: table-footer-group;
      position: relative;
      height: 200px;
      .investhub-table__col{
        height: 200px;
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &:nth-child(2n){
      background-color: $color-accent-white-dark;

      @include dark(){
        background-color: $color-accent-dark-second;
      }
    }

    &:not(:first-child){
      .investhub-table__col{
        @extend %text_table;
      }
    }
  }

  &__col{
    display: table-cell;
    padding: 10px 16px;
    @include mq_1023{
      display: flex;
      align-items: center;
      padding: 0;
    }
    @include mq_389{
      display: block;
    }

    // equals space (+) with __wrapper
    &:first-child{
      padding-left: 24px;
      @include mq_1023{
        padding-left: 0;
      }
    }

    &:not(:last-child){
      @include mq_1023{
        margin-bottom: 12px;
      }
    }
    &:last-child{
      padding-right: 24px;
      @include mq_1023{
        padding-right: 0;
      }
    }

		&:nth-child(2n){
			@include mq_1023{
        // background color
      }
		}

    &-middle{
      vertical-align: middle;
    }
  }

  &__label{
    @extend %label_table;
    margin-bottom: 12px;
    display: none;
    flex: 1;
    @include mq_1023{
      display: block;
      margin-bottom: 0;
    }
    @include mq_389{
      margin-bottom: 8px;
    }
  }

  &__btns{
    display: flex;
    margin: 0 -6px -6px;
  }
  &__btn{
    margin: 0 6px 6px;
  }

  &__link{
    color: $color-accent-primary;
    &:hover,
    &:focus{
      color: $color-accent-primary-dark;
    }
  }

  &__pagination{
    margin-top: 24px;
    .MuiTablePagination-root{
      width: 100%;
      display: block;
      border: none;
      overflow: initial;
    }

    .MuiToolbar-root{
      padding: 0;

      p{
        font-family: $font_poppins !important;
        font-size: 14px;
        font-weight: 500;
        color: $color-accent-dark;

        @include dark(){
          color: $color-accent-white;
        }
      }

      .MuiTablePagination-caption{
        order: 1;

        @include mq_639{
          display: none;
        }
      }

      .MuiTablePagination-input{
        order: 2;

        @include mq_639{
          display: none;
        }

        + .MuiTablePagination-caption{
          order: 4;
        }
      }
      
      .explore__pagination{
        order: 3;
        margin: 0;
        &_button{
          top: 0;
          bottom: 0;
          right: 0;
        }
      }

      .MuiSelect-select{
        border: 2px solid $color-accent-gray;
        border-radius: 12px;
        transition: border .2s;
        color: $color-accent-dark;

        @include dark(){
          color: $color-accent-white;
          border-color: $color-accent-dark;
        }
      }

      .MuiSvgIcon-root{
        fill: $color-accent-dark-light;

        @include dark{
          fill: $color-accent-white;
        }
      }


    }

    .MuiTablePagination-spacer{
      display: none;
    }
  }

  // variant white background with border color
  &__white{
    .investhub-table{
      &__row{
        background-color: $color-accent-white;

        @include dark(){
          background-color: transparent !important;
        }

        @include mq_1023{
          padding: 0;
        }
      }
      &__col{
        border-bottom: 1px solid $color-accent-gray;

        @include dark(){
          border-color: $color-accent-dark;
        }

        @include mq_1023{
          padding-bottom: 12px;
          margin-bottom: 12px;
        }

        @include mq_639{
          display: block;
        }
      }

      &__label{
        @include mq_639{
          margin-bottom: 8px;
        }
      }
    }
  }
}

.investhub-table__row-inline{
  .investhub-table{
    &__col{
      vertical-align: middle;
    }
  }

  .investhub-select .MuiSelect-root{
    max-width: 200px;

    @include mq_1023{
      max-width: 100%;
    }
  }
}

.investhub-table__horizontal{
  .popup{
    &__row{
      border-bottom: 1px solid $color-accent-gray;
      padding-bottom: 12px;
      align-items: center;

      @include dark(){
        border-color: $color-accent-dark;
      }

      @include mq_639{
        display: block;
      }

    }
    &__col{
      @extend %text_table;

      &:first-child{
        @extend %label_table;

        @include mq_639{
          margin-bottom: 12px;
        }
      }

      &:last-child{
        @include mq_639{
          padding-left: 0;
        }
      }
    }
  }
}

// only for subscription
.investhub-table__box-subscription{

  .field__checkbox{
    @include mq_1023{
      width: initial;
      margin-right: 0;
    }

    .MuiCheckbox-root{
      @include mq_1023{
        margin-right: 0;
      }
    }
  }

  .investhub-table__col{
    &:first-child{
      padding-bottom: 12px;
      border-bottom: 1px solid $color-accent-gray;

      @include dark{
        border-color: $color-accent-dark;
      }
    }
    &:not(:first-child){
      .investhub-table__label{
        font-size: 14px;
        text-transform: initial;

        color: $color-accent-dark-second;
        @include dark{
          color: $color-accent-white-dark;
        }
      }
    }
  }
}
.wrapper {
  :global {
    .field {
      &__buy {
        &::-webkit-inner-spin-button {
          display: none;
        }
      }
    }
  }
 }
.header{
  position: absolute;
  z-index: 20;
  padding: 20px 0;
  top: 0;
  left: 0;
  width: 100%;

  @media only screen and (max-width: 767px) {
    padding: 48px 0 24px;
  }

  &__center{
    display: flex;
    align-items: center;
  }

  &__logo{
    position: relative;
    z-index: 12;
    display: inline-block;
    flex-shrink: 0;
    width: 178px;
    margin-right: 32px;

    @media only screen and (max-width: 1179px) {
      margin-right: auto;
    }

    a{
      display: block;
    }

    img{
      width: 100%;
    }
  }

  &__wrapper{
    display: flex;
    align-items: center;
    flex-grow: 1;

    @media only screen and (max-width: 1023px) {
      flex-grow: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      flex-direction: column;
      align-items: stretch;
      min-height: calc(var(--vh, 1vh) * 100);
      margin: 0;
      padding: 140px 32px 40px;
      box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.2);
      background: var(--color-bg-black);
      visibility: hidden;
      opacity: 0;
      transition: all .2s;
    }
  }

  &__nav{
    display: flex;
    margin-right: auto;

    @media only screen and (max-width: 1023px) {
      flex-direction: column;
      margin: 0 0 40px;
      border: none;
    }
  }

  &__link{
    margin-left: 32px;
    font-family: var(--font-2);
    font-weight: 600;
    line-height: 40px;
    letter-spacing: .5px;
    color: #777E90;
    transition: color .2s;
    padding-bottom: 12px;

    &:hover,
    &:focus{
      color: var(--color-blue);
    }

    @media only screen and (max-width: 1023px) {
      margin-left: 0;
      font-size: 24px;
      line-height: 64px;
      color: #777E90;
    }
  }

  &__lists{
    list-style: none outside none;
    display: flex;
    flex-flow: row wrap;
  }

  &__item{
    position: relative;
  }

  &__subitem{
    min-width: 1170px;
    height: auto;
    position: absolute;
    top: 90%;
    left: -324px;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    padding: 32px;
    -webkit-transition: 0.3s;
    transition: 0.2s;
    background-color: #22262E !important;
    pointer-events: none;
    border-radius: 12px;

    @media only screen and (max-width: 1179px) {
      min-width: 769px;
      left: -164px;
    }

    &_title{
      font-family: var(--font-2) !important;
      font-weight: normal !important;
      line-height: 1.2 !important;
      letter-spacing: -.01em !important;
      color: white !important;
      margin-bottom: 24px !important;
    }

    &_item{
      > *{
        &:not(:last-child){
          margin-bottom: 32px;
          display: block;
        }
      }
    }
  }

  &__subitems{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 24px;
    list-style: none outside none;
    padding-left: 0;
  }

  &__subitem_link,
  &__subitem_nonlink{
    font-family: var(--font-2);
    font-size: 16px;
    line-height: 1.5;
    color: white;
    padding-right: 24px;
    text-align: left;

    > span{
      color: var(--color-yellow);
      font-family: var(--font-1) !important;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 16px;
      display: block;
      transition: all .2s ease;
    }

    > div{
      br{
        display: none;
      }
    }
  }

  &__subitem_link{
    &:hover,
    &:focus{
      > span{
        color: var(--color-blue);
      }
    }
  }

  &__cta{

    a{
      &:not(:last-child){
        margin-right: 12px;

        @media only screen and (max-width: 1023px) {
          margin-right: 0;
        }
      }
      @media only screen and (max-width: 1023px) {
        margin-top: 16px;
        display: inline-flex;
        width: 100%;
      }
    }
  }

  &__burger{
    display: none;
    position: relative;
    width: 32px;
    height: 32px;
    background: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-left: 24px;

    @media only screen and (max-width: 1023px) {
      display: block; 
    }

    &:before,
    &:after{
      content: '';
      position: absolute;
      top: 16px;
      left: 6px;
      width: 20px;
      height: 2px;
      background: #777E90;
      border-radius: 2px;
      transition: -webkit-transform .2s;
      transition: transform .2s;
      transition: transform .2s, -webkit-transform .2s;
    }
    &:before{
      transform: translateY(-4px);
    }
    &:after{
      transform: translateY(3px);
    }
  }
}



/* ------------------------------------------------------------------- 
 * ## fonts 
 * ------------------------------------------------------------------- */
 @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Space+Grotesk:wght@400;600;700&display=swap');

:root {
  --font-1: 'Inter', sans-serif;
  --font-2: 'Space Grotesk', sans-serif;
}


/* ------------------------------------------------------------------- 
 * ## colors
 * ------------------------------------------------------------------- */
:root{
  /* primary
   */  
  --color-blue: #3772FF;
  --color-blue-dark: #044eff;
  --color-yellow: hsla(33, 95%, 93%, 1);
  --color-gold: #FEEEDA;
  --color-dark: hsla(240, 5%, 8%, 1);
  --color-dark-lighter: rgb(38, 38, 42);
  --color-light: hsla(240, 20%, 99%, 1);
  --color-light-lighter: hsla(240, 20%, 89%, 1);


  /* shades
   * will generated after reorder position
   */
  // #23262F
  // #353945
  // #777E90
  // #E6E8EC
  // #FCFCFD

  /* border
   */
  --color-border-light: #E6E8EC;
  --color-border-dark: #353945;
  --color-border-focus: #777E90;

  /* background color
   */
  --color-bg-black: #141416;
}

/* ------------------------------------------------------------------- 
 * ## spacing
 * ------------------------------------------------------------------- */
:root{
  /* spacing
   */
  --base-size: 16px;
  --space-xs: 4px;
  --space-sm: 8px; // 8px
  --space: var(--base-size);
  --space-md: 24px;
  --space-xl: 32px;
  --space-xxl: 40px;
}
 
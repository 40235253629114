.new-tenant{
  &__row{
    @include mq_1023{
      flex-flow: row wrap;
    }
  }
  &__col{
    &:first-child{
      width: 214px;

      @include mq_1023{
        width: 100%;
      }
    }
    &:last-child{
      flex-grow: 1;
      padding-left: 225px;

      @include mq_1419{
        padding-left: 80px;
      }
      @include mq_1023{
        padding-left: 0;
        width: 100%;
      }
    }
  }

  &__foot{
    flex-flow: row wrap;
    .details{
      &__btns{
        @include mq_1023{
          align-items: center;
        }
      }

      &__button{
        @include mq_767{
          width: initial;
          margin-bottom: 0 !important;
        }

        @include mq_639{
          font-size: 14px;
          padding-left: 16px;
          padding-right: 16px;
        }

        @include mq_478{
          width: 100%;
        }

        &:not(:last-child){
          @include mq_478{
            margin-bottom: 24px !important;
          }
        }
        &:not(:first-child){
          @include mq_478{
            margin-top: 24px !important;
          }
        }
      }
    }
  }

  &__stepper{
    &-nav{
      // Wrapper
      .MuiStepper-root{
        padding: 0;
        background: transparent;
      }
      // Icon
      .MuiStepLabel-iconContainer{
        position: relative;
        padding-right: 16px;
      }
      .MuiSvgIcon-root{
        fill: $color-accent-dark-light;
        width: 21px;
        height: 21px;

        &.MuiStepIcon-active,
        &.MuiStepIcon-completed{
          fill: $color-accent-dark-second;

          @include dark(){
            fill: $color-accent-gray-light;
          }

          .MuiStepIcon-text{
            @include dark(){
              fill: $color-accent-dark;
            }
          }
        }
      }

      // Typography
      .MuiTypography-root{
        font-family: $font_dmsans;
        font-size: 14px;
        line-height: 1.14286;
        font-weight: 700;
        color: $color-accent-dark-light;
        transition: all .2s;

        &.MuiStepLabel-active,
        &.MuiStepLabel-completed{
          color: $color-accent-dark-second;

          @include dark(){
            color: $color-accent-dark-light;
          }

        }
      }

      // Connector vertical
      .MuiStepConnector{
        &-vertical{
          margin-left: 9px;
          position: relative;
          top: 4px;
        }

        &-lineVertical{
          min-height: 24px;
        }

        &-line{
          @include dark(){
            border-color: $color-accent-dark;
          }
        }
      }
    }

    &-mobile{

      @include mq_478{
        width: 100%;
      }

      .MuiStepper-root{
        @include mq_478{
          justify-content: center;
        }
      }
      .MuiStepIcon-text,
      .MuiStepLabel-labelContainer,
      .MuiStepConnector-root{
        display: none;
      }

      .MuiSvgIcon-root{
        width: 8px;
        height: 8px;
      }

      .MuiStepper-root{
        flex-direction: row;
        justify-content: center;
      }

      .MuiStepLabel-iconContainer{
        padding-right: 8px;
      }
    }
  }

  &__table{
    display: block;

    .investhub-table__row{
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      @include mq_639{
        flex-direction: column;
      }

      &:first-child{
        @include mq_1023{
          display: none;
        }
      }
    }
    .investhub-table__col{
      @include mq_639{
        width: 100%;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
      }
      &:first-child{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    button{
      @include mq_639{
        width: 100%;
      }
    }
  }
  &__row-table{
    strong{
      font-weight: 700;
      display: block;
      margin-bottom: 12px;
    }
    .investhub-table__col{
      padding-top: 16px;
      padding-bottom: 16px;
      vertical-align: middle;
      font-weight: 400 !important;
      font-family: $font_poppins !important;
      line-height: 1.4 !important;
    }

    [class^="button"]{
      min-width: 100px;
    }
  }

  &__row-note{
    .investhub-table__col{
      padding: 0;
    }
    .box-note{
      border-radius: 0;
    }
  }

  &__2fa{
    padding-top: 32px;
    border-top: 1px solid $color-accent-gray;

    @include dark{
      border-color: $color-accent-dark-light;
    }

    &-text{
      margin-bottom: 16px;
    }
  }

  .upload__note{
    a{
      color: $color-accent-primary;
      font-weight: 700;

      &:hover,
      &:focus{
        color: $color-accent-primary-dark;
      }
    }
  }

  .field__group-addremoval{
    @include mq_639{
      margin-top: 24px;
    }
  }
}
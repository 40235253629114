.field__radio{
  // combine with material UI
  // icon
  .MuiSvgIcon-root{
    fill: $color-accent-gray-dark;
  }

  .Mui-checked .MuiSvgIcon-root{
    fill: $color-accent-primary;
  }

  .Mui-checked + .MuiTypography-root{
    @include dark(){
      color: $color-accent-gray-light;
    }
  }

  // typography
  .MuiTypography-root{
    line-height: 1.71429;
    font-weight: 500;
    color: $color-accent-gray-dark;
    font-family: $font_dmsans;
    font-size: 14px;
    text-align: left; 
  }

  .Mui-checked + .MuiTypography-root{
    color: $color-accent-dark-second;
  }

  // error
  + .MuiBox-root{
    .text-error{
      color: $color-accent-red;
      font-family: $font_dmsans;
      font-weight: 700;
    }
  }
}

// #######################################
// Radio Button Combine with Material UI
// #######################################
.radio-button{
  &__group{
    display: flex;
    flex-flow: row wrap;
  }

  &__wrap{
    position: relative;
    margin-bottom: 12px;
    overflow: hidden;

    @media only screen and (max-width: 767px) {
      margin-bottom: 8px;
    }
    &:not(:last-child){
      margin-right: 16px;

      @media only screen and (max-width: 767px){
        margin-right: 8px;
      }
    }
  }

  &__field{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    cursor: pointer;

    &:checked{
      + .radio-button__text{
        background: #0045ea;
        color: white;
      }
    }
  }

  &__text{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 20px;
    padding: 0 16px;
    font-size: 14px;

    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    line-height: 1;
    background: none;
    box-shadow: 0 0 0 2px #E6E8EC inset;
    color: #23262F; 
    transition: all .2s;
    cursor: pointer;

    @include darkOutside{
      box-shadow: 0 0 0 2px #353945 inset;
      color: #FCFCFD;
    }

    &:hover{
      background: #0045ea;
      color: white;
    }
  }
}
.select,
.investhub-select{
  // combine with material ui
  // reset
  .MuiInput-underline:before,
  .MuiInput-underline:after,
  .MuiSelect-icon{
    @extend %fieldResetHide;
  }
  .MuiInputBase-input{
    @extend %fieldInputWrapperHasIcon;

    height: 48px;
    line-height: 48px;
  }

  &__trans{
    background-color: transparent;
  }
}
.MuiPaper-root.MuiMenu-paper{
  // box-shadow: 0 0 0 2px #e6e8ec inset !important;
  color: #23262F;
  border: 2px solid #e6e8ec;
  border-radius: 6px !important;

  img{
    margin-right: 8px;
    position: relative;
    top: -1px;
  }
}
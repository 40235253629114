.wrapper {

  :global{
    .assets{
      margin-left: -1.5rem;
      margin-right: -1.5rem;

      @media screen and (max-width: 767px) {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      &::-webkit-scrollbar{
        width: 8px; 
        height: 8px;
      }

      &::-webkit-scrollbar-track{
        background-color: initial;
      }

      &::-webkit-scrollbar-thumb{
        border-radius: 6px;
        background-color: #2b2d33;
      }
      &::-webkit-scrollbar-thumb:active,
      &::-webkit-scrollbar-thumb:hover{
        background-color: #6F737E;
      }
    }

    tr{
      th,
      td{
        &:first-child{
          padding-left: 1.5rem;

          @media screen and (max-width: 767px) {
            padding-left: 1rem;
          }
        }

        &:last-child{
          padding-right: 1.5rem;

          @media screen and (max-width: 767px) {
            padding-right: 1rem;
          }
        }
      }
    }
    tbody{
      tr:nth-child(odd){
        background-color: #1e2025;
      }
    }
  }
}

/* ===================================================================
 * Investhub Stylesheet
 * Template Ver. 2.0.0
 * Some classes takes from styleshout.com template
 * ------------------------------------------------------------------
 *
 * TOC:
 * # SETTINGS
 *      ## fonts
 *      ## colors
 *      ## spacing and typescale
 * # UTILITIES
 *      ## ShortenText
 * # BUTTONS
 * # FIELD
 * # BREADCRUMB
 * # PAGEWRAP
 * # HEADER
 *      ## logo
 *      ## wrapper custom
 *      ## navigation
 *      ## translation
 * # HEADER
 * # FOOTER
 * # SECTION
 * # TAB
 * # CHARTS
 * # STEPPER
 * # FIXING AFTER MERGE TWO HEADERS, FOOTERS
 * ------------------------------------------------------------------ */

/* ===================================================================
 * # SETTINGS
 *
 *
 * ------------------------------------------------------------------- */
@import './settings/settings';

.hero, .h1, .h2, .h3, .h4{
  font-family: var(--font-heading);
}

/* ===================================================================
 * # UTILITIES
 *
 *
 * ------------------------------------------------------------------- */
/* -------------------------------------------------------------------
 * ## ShortenText
 * ------------------------------------------------------------------- */
.shorten__text-wrap {
  font-family: var(--font-2);
  @media only screen and (min-width: 768px) {
    position: relative;
    display: inline-block;
  }

  .shorten__text{
    white-space: nowrap;
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    position: relative;
    padding: 4px;
    top: 4px;
  }

  > .full__text{
    position: absolute;
    padding: 4px 8px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
    background-color: var(--color-dark);
    border-radius: 4px;
    z-index: 1;
    color: white;
  }

  &:hover,
  &:focus{
    > .shorten__text{
      @media only screen and (min-width: 768px) {
        visibility: hidden;
      }
    }

    > .full__text{
      @media only screen and (min-width: 768px) {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

/* ===================================================================
 * # BUTTONS
 *
 *
 * ------------------------------------------------------------------- */
 @import './components/button';

/* ===================================================================
 * # FIELDS
 *
 *
 * ------------------------------------------------------------------- */
/*  
 * ## Field with material ui
 **/
.dark-mode .investhub__field.MuiTextField-root input{
  border-color: #353945;
  color: #FCFCFD;
}
.dark-mode .investhub__field.MuiTextField-root .Mui-focused input{
  border-color: #777E90;
}

/* ===================================================================
 * # BREADCRUMB
 *
 *
 * ------------------------------------------------------------------- */
.site-header + .outer__inner {
  .control{
    // background: #F4F5F6;
    border-top: 1px solid #E6E8EC;
  }
}
body.dark-mode{
  .site-header + .outer__inner {
    .control{
      // background: #23262F ;
      border-color: #353945;
    }
  }
}

/* ===================================================================
 * # PAGEWRAP
 *
 *
 * ------------------------------------------------------------------- */
.pagewrap{
  background-color: var(--color-bg-black);
}

// will remove after merge all content
html body.light-mode{
  background-color: #FCFCFD;
}

/* ===================================================================
 * # HEADER
 *
 *
 * ------------------------------------------------------------------- */
.outer.v2{
  > header{
    position: relative;

    @media only screen and (max-width: 767px){
      padding: 24px 0;
    }
  }

  .header__logo-dark{
    position: relative;
  }
}

// REMOVE CTA
.outer.v2.admin,
.outer.v2.page-dashboard {
  footer {
    // margin-top: 120px;
    // margin-bottom: 0;
    > div:last-child{
      padding-top: 0;
    }

    > div:first-child{
      margin-bottom: 0;
      .center{
        > div{
          display: none;
        }
      }
    }
  }
}
.outer.v2.pricing {
  footer{
    background-color: #23262F;

    > div:last-child{
      border-top: none;
    }
  }
}
/* -------------------------------------------------------------------
 * ## Logo
 * ------------------------------------------------------------------- */
body.dark-mode{
  .header__logo{
    &-dark{
      opacity: 1;
      visibility: visible;
    }
    &-light{
      opacity: 0;
      visibility: hidden;
    }
  }
}

/* -------------------------------------------------------------------
 * ## Wrapper custom
 * ------------------------------------------------------------------- */
.header-wrapper__custom{
  box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.2);
  background: #FCFCFD;

  &.expanded{
    visibility: visible;
    opacity: 1;
  }
}
body.dark-mode{
  .header-wrapper__custom{
    @media only screen and (max-width: 1023px) {
      box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.2);
      background: var(--color-bg-black);
    }
  }
}

.header-burger__custom{
  &.expanded{
    &:before{
      transform: translateY(0) rotate(-45deg);
    }
    &:after{
      transform: translateY(0) rotate(45deg);
    }

    span{
      &:first-child{
        transform: translateY(0) rotate(-45deg);
      }
      &:last-child{
        transform: translateY(0) rotate(45deg);
      }
    }
  }
}

/* -------------------------------------------------------------------
 * ## Navigation
 * ------------------------------------------------------------------- */
.site-header__megamenu-parent{

  &:hover,
  &:focus{
    .site-header__megamenu-child{
      opacity: 1;
      visibility: visible;
      top: 100%;
      pointer-events: auto;
    }
  }


  .site-header__megamenu-child{
    background-color: white;

    .header__title{
      color: #23262F;
    }
  }
}

body.dark-mode{
  .site-header__megamenu-child{
    background-color: #22262E;

    .header__title{
      color: white;
    }
  }
}

.header__subitem_nonlink,
.header__subitem_link{
  > span{
    color: #23262F;
  }

  > div{
    color: #777E90;
  }
}
.header__subitem_link{
  &:hover,
  &:focus{
    > span{
      color: var(--color-blue) !important;
    }
  }
}
body.dark-mode{
  .header__subitem_nonlink,
  .header__subitem_link{
    > span{
      color: var(--color-yellow);
    }

    > div{
      color: white;
    }
  }
}

/* -------------------------------------------------------------------
 * ## translation
 * ------------------------------------------------------------------- */
 .site-translation{
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease;
  position: absolute;
  top: 4px;
  &:not(.site-translation__active){
    opacity: 1;
    visibility: visible;
  }

  &__rwd{
    &.has-active{
      color: var(--color-blue) !important;
    }
  }
}

.header-wrapper__custom{
  .footer__head{
    color: #23262F;
  }
}
body.dark-mode{
  .header-wrapper__custom{
    .footer__head{
      color: white;
    }
  }
}
.pagewrap .header-wrapper__custom .footer__head{
  color: white;
}

/* ===================================================================
 * # FOOTER
 *
 *
 * ------------------------------------------------------------------- */

/* ===================================================================
 * # FOOTER
 *
 *
 * ------------------------------------------------------------------- */
.footer-head__custom{
  &.expanded{
    svg{
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

/* ===================================================================
 * # SECTION
 *
 *
 * ------------------------------------------------------------------- */
@import './components/section';


.legend-map{
  opacity: 1 !important;
}

/* ===================================================================
 * # TAB
 *
 *
 * ------------------------------------------------------------------- */
 @import './components/tab';

/* ===================================================================
 * # CHARTS
 *
 *
 * ------------------------------------------------------------------- */
.adoption__graphic{
  .recharts-surface{
    @media only screen and (max-width: 767px){
      width: 120%;
      margin-left: -15%;
    }
  }
  .recharts-default-tooltip{
    background-color: #272B30 !important;
    border: 1px solid #272B30 !important;
    border-radius: 12px;
  }

  .recharts-layer {
    .recharts-text{
      fill: white;
    }
  }
  .recharts-tooltip-label{
    font-family: var(--font-1) !important;
    font-size: 36px;
    font-weight: 600;
    color: var(--color-gold);
    letter-spacing: -.02em;
    margin-bottom: 0px;

    @media only screen and (max-width: 1023px){
      font-size: 24px;
    }
    @media only screen and (max-width: 767px){
      font-size: 18px;
    }
  }
  .recharts-tooltip-item-list{
    margin: 0;
    li{
      font-family: var(--font-2);
      // color: white !important;
      font-size: 16px;
      margin: 0;
      line-height: 1.2;

      &:not(:last-child){
        margin-bottom: 0px;
      }

      > *{
        margin: 0;
      }
    }
  }

  .recharts-default-legend{
    li{
      margin: 0 !important;
      &:not(:last-child){
        margin-right: 24px !important;

        @media only screen and (max-width: 767px){
          margin: 0 12px !important;
        }
      }
    }
    .recharts-surface{
      position: relative;
      top: -3px;
    }
  }
  .recharts-legend-item{
    font-family: var(--font-2) !important;
  }
  .recharts-legend-item-text{
    font-size: 18px !important;
    // color: var(--color-gold) !important;

    @media only screen and (max-width: 767px){
      font-size: 16px !important;
    }
  }
}

/* ===================================================================
 * # STEPPER
 *
 *
 * ------------------------------------------------------------------- */
 @import './components/stepper';

 /* ===================================================================
  * # CUSTOMER SAY
  *
  *
  * ------------------------------------------------------------------- */
.customersay{
  .investhub-blog__item{
    border-color: #353945;
  }
  .investhub-blog__title{
    color: white;
    font-family: var(--font-1);
  }

  .investhub-blog__category,
  .investhub-blog__date{
    font-family: var(--font-2);
  }
}

/* ===================================================================
 * # FIXING AFTER MERGE TWO HEADERS, FOOTERS
 *
 *
 * ------------------------------------------------------------------- */
.techstack{
  &__center{
    max-width: 1280px;
  }

  // Item
  &__item{

    &:hover,
    &:focus{
      .techstack{
        &__image{
          width: 100px;
        }
      }
    }

    &-unintegrated{
      .techstack__image img,
      .popular__name{
        opacity: .45;
      }

      // &:hover,
      // &:focus{
      //   box-shadow: none;
      //   .techstack__image{
      //     width: 64px !important;
      //   }
      // }
    }

    &-image_v1{
      .techstack__image{
        width: 64px !important;
      }
    }

    &-image_v2{
      .techstack__image{
        width: 142px !important;
      }

    }

    &-image_v3{
      .techstack__image{
        width: 154px !important;
      }
    }
  }
  &__body{
    padding: 24px 16px;
  }
  &__image{
    &:before{
      position: absolute;
      transition: all .2s;
    }

    img{
      position: relative;
      width: 100%;
      max-height: 78px;
      backface-visibility: hidden;
      transition: all .2s;
      object-fit: contain;
      object-position: center;
    }

    &-lightmode{
      @include dark(){
        display: none !important;
      }
    }
    &-darkmode{
      display: none !important;

      @include dark(){
        display: block !important;
      }
    }
  }

  &__name{
    margin-top: 24px;
    margin-bottom: 12px;
    font-size: 18px;
  }

  &__text{
    margin-bottom: 0;
    font-size: 14px;
  }
}
.techstack-first{
  .popular{
    &__name{
      min-height: 48px;

      @include mq_767{
        min-height: initial;
      }
    }
  }
  .techstack{
    &__item{
      min-height: 230px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include mq_767{
        min-height: initial;
      }
    }

    &__image{
      min-height: 80px;
      display: flex;
    }

    &__list{
      margin: 0 -16px -32px;
      @include mq_767{
        display: flex;
        margin: 0 -16px -32px;
      }
      @include mq_639{
        margin: 0 -8px -16px;
      }
    }

    &__item{
      flex: 0 0 calc(20% - 32px);
      max-width: calc(20% - 32px);
      margin: 0 16px 32px;

      @include mq_1023{
        flex: 0 0 calc(33.333% - 32px);
        max-width: calc(33.333% - 32px);
      }
      @include mq_639{
        flex: 0 0 calc(50% - 16px);
        max-width: calc(50% - 16px);
        margin: 0 8px 16px;
      }
    }
  }
}
.techstack-second{
  .techstack{
    &__stage{
      margin-bottom: 48px;
    }

    &__list{
      margin: 0 -16px -32px;
      @include mq_767{
        display: flex;
        margin: 0 -16px -32px;
      }
      @include mq_639{
        display: block;
        margin: 0;
      }
    }

    &__item{
      flex: 0 0 calc(33.333% - 32px);
      max-width: calc(33.333% - 32px);
      margin: 0 16px 32px;

      @include mq_767{
        flex: 0 0 calc(50% - 32px);
        max-width: calc(50% - 32px);
      }

      @include mq_639{
        max-width: 100%;
        width: 100%;
        margin: 0;
      }

      &:not(:last-child){
        @include mq_639{
          margin-bottom: 24px;
        }
      }
    }
  }

  .main__head{
    &.techstack__head{
      margin-bottom: 84px;
    }
  }

  &__bg{
    padding-top: 128px;
    background: linear-gradient(178deg, $color-accent-white-dark, rgba(255, 255, 255, 0) 35%);

    @include dark(){
      background: linear-gradient(178deg, $color-accent-dark-dark, rgba(255, 255, 255, 0) 35%);
    }
  }
}
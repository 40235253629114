.footer{
  &__logo{
    img{
      width: 178px;
    }
  }
  &__link{
    &.no__link{
      &:hover,
      &:focus{
        color: $color-accent-dark-light;
      }
    }
  }

  &__group{
    // overwrite accordion
    .investhub-accordion{
      border-radius: initial;
      background-color: transparent;
      box-shadow: none;
      &__summary{
        padding-left: 0;
        padding-right: 0;
        min-height: initial;

        .MuiAccordionSummary-content{
          margin-top: 0;
          margin-bottom: 0;
        }

        .MuiIconButton-root{
          padding-right: 8px;
        }
        .MuiSvgIcon-root{
          width: 18px;
          height: 18px;

          @include dark(){
            fill: $color-accent-dark-light;
          }
        }
      }

      &__details{
        padding: 0;
      }

      .footer{
        &__head{
          @include dark(){
            color: $color-accent-white;
          }
        }

        &__body{
          display: block;
        }
      }
    }
  }

  &__theme{
    @include mq_639{
      position: relative;
    }
  }

  &__note{
    color: $color-accent-dark-light;
  }
}
@import 'utils/text-color';
@import 'utils/background-color';

.nospace {
  &-top {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  &-bottom {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
}

.space {
  width: 100%;
  display: block;

  &-8{
    height: 8px;
  }

  &-16{
    height: 16px;
  }

  &-24{
    height: 24px;
  }

  &-32 {
    height: 32px;
  }
}

.clickable {
  cursor: pointer;
}

.MuiSvgIcon-root.page-icon {
  font-size: 70px;
}

.d__flex {
  display: flex;
}

.flex__wrap {
  flex-wrap: wrap;
}

.flex__justify_between {
  justify-content: space-between;
}

.flex__justify_end {
  justify-content: flex-end;
}

.clear-border{
  &__bottom{
    border-bottom: none;
  }
}

.pb{
  &-0{
    padding-bottom: 0;
  }
}

.mb{
  &-0{
    margin-bottom: 0;
  }
}
.intro{
  &__center{
    max-width: 1280px;
    margin: 0 auto;
  }

  &__list{
    @include mq_1023{
      display: block;
    }
  }

  &__item{
    border: none;
    padding: 0;

    @include mq_1023{
      width: 100%;
      text-align: center;
    }


    &:not(:last-child){
      @include mq_1023{
        margin-bottom: 80px;
      }
    }

    &:hover,
    &:focus{
      box-shadow: none;
    }

    br{
      @include mq_1023{
        display: none;
      }
    }
  }

  &__info{
    font-size: 14px;
  }

  &__button{
    margin-top: 40px;

    @include mq_767{
      margin-top: 0;
    }
  }
}
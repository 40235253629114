.admin-page-body {
  position: relative;

  > .MuiDrawer-root {
    flex-shrink: 0;

    > .MuiDrawer-paper {
      position: relative;
    }
  }

  > main {
    padding: 20px;
  }
}

@media (min-width: 768px) {
  $drawer-width-md: 180px;

  .admin-page-body {
    display: flex;

    > .MuiDrawer-root > .MuiDrawer-paper {
      width: $drawer-width-md;

      .MuiListItemIcon-root {
        min-width: 35px;
      }
    }

    > main {
      width: calc(100% - #{$drawer-width-md});
    }
  }
}
